import React, { useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Table, Card, CardBody, Button, Col } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { withTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useCallback } from 'react'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import AlertService from '../../../services/alertService'
import uuid from 'react-uuid'
import ApiService from '../../../services/apiService'
import NoData from '../../../components/NoData/NoData'
import { serverImgURL } from '../../../helpers'
import ActionButton from '../../../components/Buttons/ActionButton'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'

const buttonSpinnerId = uuid();
const Categories = (props) => {

  const { history } = props;
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [dragCategoryId, setDragCategoryId] = useState(null);

  useEffect(() => {
    getCategories();
  }, [])

  const getCategories = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCategories().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCategories(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteCategory = (categoryId, spinnerId) => {
    if (!categoryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      ApiService.deleteCategory(categoryId).then(response => {
        setCategories(categories.filter(item => item._id !== categoryId));
        AlertService.alert("success", "Category deleted successfully");
        getCategories();
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const drop = (dropCategoryId) => {
    if (dropCategoryId && dragCategoryId && dropCategoryId !== dragCategoryId) {
      const spinnerId = uuid();
      setPageSpinner(spinnerId)
      ApiService.changeCategoryDisplayOrder(dropCategoryId, dragCategoryId).then(() => {
        getCategories()
        setDragCategoryId(null);
        extractPageSpinner(spinnerId);
        AlertService.alert("success", "Data saved")
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const drag = (categoryId) => {
    categoryId && setDragCategoryId(categoryId)
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  const categoryTable = (categories) => {
    if (categories && categories.length) {
      return <Table bordered responsive className="text-center">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th className="align-middle">Image</th>
            <th className="align-middle">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((item, index) => (
            // <Auxiliary>
              <tr
                key={item._id}
                style={{ cursor: "move" }}
                draggable={true}
                onDrop={() => drop(item._id)}
                onDragOver={event => event.preventDefault()}
                onDragStart={() => drag(item._id)}
              >
                <td className="align-middle"><b>{index + 1}</b></td>
                <td className="align-middle"><b>{item._name?.am || ""}</b></td>
                <td className="align-middle">
                  <img
                    src={serverImgURL(item.mainImage)}
                    style={{ maxHeight: 40 }}
                    alt="/"
                  />
                </td>

                <td className="align-middle">
                  <div
                    className="d-flex justify-content-center gap-1"
                  >
                    <Button
                      color="warning"
                      onClick={() => history.push(`/category-form/${item._id}`)}
                    >
                      Edit
                    </Button>
                    <ActionButton
                      type="button"
                      name="Delete"
                      color="danger"
                      spinnerClass={`w_50`}
                      spinnerId={`${buttonSpinnerId}_${index}`}
                      onClick={() => deleteCategory(item._id, `${buttonSpinnerId}_${index}`)}
                    />
                  </div>
                </td>
              </tr>
            //   <td colSpan="4" className='ps-5'>
            //     {categoryTable(item.nodes)}
            //   </td>
            // </Auxiliary>
          ))}
        </tbody>
      </Table>
    }
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Categories</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Categories"
        />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/category-form`)
              }}
            >
              Create a category
            </Button>
          </Col>
        </Row>
        <Row>
          {
            categories && categories.length ?
                  categoryTable(categories)
              : <NoData />
          }
        </Row>
      </Container>
    </div>
  )
}



export default withTranslation()(Categories)