import React, { useState, useEffect, useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Card, CardBody, CardTitle, CardSubtitle, Input, Form, Col, Label, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { withTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import MainService from "./../../../services/mainService"
import { ERROR_KEY, MAXIMUM_UPLOAD_FILE_SIZE, VALID_IMAGE_TYPES_KEY, fieldValidation } from '../../../constants/mainKeys'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AlertService from '../../../services/alertService'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { ProgressBar } from 'react-bootstrap'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import { serverImgURL } from '../../../helpers'

const buttonSpinnerId = uuid();
const CategoryForm = (props) => {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const { history } = props;

  const [values, setValues] = useState({
    parent: "",
    _name: {
      am: "",
      en: "",
      ru: ""
    },
    mainImage: "",
    backgroundImage: "",
    coverTitle: "",
    coverDescription: "",
  });

  const { categoryId } = useParams();
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [productData, setProductData] = useState(null);
  const [changes, setChanges] = useState(false);
  const [categories, setCategories] = useState([]);
  const [fileReadPercent, setFileReadPercent] = useState(0);

  useEffect(() => {
    // getCategoriesList();
    if (categoryId) getCategoryById();
    return () => {
      // setCategories([]);
      setProductData(null);
    }
  }, [])

  const getCategoryById = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCategoryById(categoryId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setProductData(data);
        if (!data._name) {
          data._name = {
            am: "",
            en: "",
            ru: ""
          }
        }
        setValues((values) => ({
          ...values,
          parent: data.parent || "",
          _name: data._name,
          // mainImage: data.mainImage,
          // backgroundImage: data.backgroundImage,
          coverTitle: data.coverTitle,
          coverDescription: data.coverDescription,
        }))
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCategoriesList = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCategoriesList().then(response => {
      if (response && response.data && response.data.data) {
        const data = [...response.data.data];
        data.unshift({
          title: "Not selected",
          _id: "",
        })
        setCategories(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const uploadFile = async (event, field) => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      if (file.size >= MAXIMUM_UPLOAD_FILE_SIZE * 1024 * 1024) {
        AlertService.alert("warning", "Incorrect file size");
        return false;
      }
      const fileName = file.name;
      await mainService.readFile(file, VALID_IMAGE_TYPES_KEY, setFileReadPercent).then(uploadedFile => {
        setValues((values) => ({
          ...values,
          [field]: {
            fileName,
            file,
            uploadedFile
          }
        }))
      }).catch(error => error && AlertService.alert("error", "Invalid file format"));
    };
  }

  const removeUploadedImage = (field) => {
    if (!field) { return false; }
    setValues({ ...values, [field]: "" });
  };

  const onChange = (event, field, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [fieldName]: item.value
    }))

    setIsInvalidSubmit(false);
    setChanges(true);
  }

  const onChangeTitle = (event, lang, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevValues) => ({
      ...prevValues,
      _name: {
        ...prevValues._name,
        [lang]: event.target.value,
      },
    }));
    setIsInvalidSubmit(false);
  };

  const removeImage = (type, id, imageName) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId);
      ApiService.removeImage("categories", id, imageName).then(response => {
        setProductData(value => ({
          ...value,
          [type]: null
        }))
        AlertService.alert("success", "Data saved");
        extractPageSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values._name.am.trim().length || !values._name.en.trim().length || !values._name.ru.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    const formData = new FormData();
    setButtonSpinner(buttonSpinnerId);
    Object.keys(values).forEach((key) => {
      if (key !== "mainImage" && key !== "backgroundImage") {
        // values[key] && formData.append(key, values[key]);
        if (typeof values[key] === 'object') {
          for (const nestedKey in values[key]) {
            const nestedValue = values[key][nestedKey];
            formData.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
      if (key === "mainImage") {
        if (values[key] && values[key].file) formData.append("mainImage", values[key].file);
        else formData.append("mainImage", null);
      }
      if (key === "backgroundImage") {
        if (values[key] && values[key].file) formData.append("backgroundImage", values[key].file);
        else formData.append("backgroundImage", null);
      }
    });
    (categoryId ? ApiService.updateCategory(categoryId, formData) : ApiService.createCategory(formData)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert("success", "Data saved");
      history.push("/categories")
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Category Form</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Category Form"
        />
        <hr className='mt-0' />
        <Row>
          <Form
            onChange={() => setChanges(true)}
            onSubmit={onSubmit}
          >
            <Row>
              <Col sm={12} className='mb-3'>
                <div className='border rounded p-2'>
                  <Row>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Name (am)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._name.am.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._name.am}
                          onChange={(event) => onChangeTitle(event, "am", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Name (en)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._name.en.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._name.en}
                          onChange={(event) => onChangeTitle(event, "en", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Name (ru)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._name.ru.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._name.ru}
                          onChange={(event) => onChangeTitle(event, "ru", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* {
                    categories && categories.length ?
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Select Parent Category</Label>
                          <ReactSelectOption
                            value={values.parent}
                            selectedValue={(() => {
                              const selectedValue = { ...categories.find(data => data._id === values.parent) };
                              if (Object.keys(selectedValue).length) {
                                selectedValue.label = selectedValue.title;
                                selectedValue.value = selectedValue.value;
                              }
                              return selectedValue;
                            })()}
                            className={`rounded`}
                            items={categories.map(data => ({ label: data.title, value: data._id }))}
                            onChange={item => onSelectOptionChange(item, "parent")}
                          />
                        </div>
                      </Col>
                      : null
                  } */}
              {/* {
                    !values.parent ?
                      <Auxiliary>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label>Cover Title</Label>
                            <Input
                              name="coverTitle"
                              className="form-control resize-none mt-1"
                              value={values.coverTitle}
                              onChange={(event) => onChange(event, "coverTitle", fieldValidation.length_100)}
                            />
                            <small><i>Max {fieldValidation.length_100} symbols</i></small>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label>Cover Description</Label>
                            <textarea
                              name="coverDescription"
                              rows="4"
                              className="form-control resize-none mt-1"
                              value={values.coverDescription}
                              onChange={(event) => onChange(event, "coverDescription", fieldValidation.length_2000)}
                            />
                            <small><i>Max {fieldValidation.length_2000} symbols</i></small>
                          </div>
                        </Col>
                      </Auxiliary>
                      : null
                  } */}
              <Col md={12}>
                <div className='border rounded p-2'>
                <Label>Images</Label>
                  <Row>
                    <Col md={6} className="mt-2">
                      {values.mainImage || (productData && productData.mainImage) ?
                        <div>
                          <div className='position-relative d-inline-block'>
                            <img
                              src={values.mainImage.uploadedFile ? values.mainImage.uploadedFile : productData.mainImage ? serverImgURL(productData.mainImage) : ""}
                              alt="/"
                              style={{ height: "200px" }}
                              className="img-thumbnail"
                            />
                            <Button
                              style={{ position: "absolute", top: 5, right: 5 }}
                              color="danger"
                              className="btn-sm p-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                if (values.mainImage && values.mainImage.uploadedFile) {
                                  removeUploadedImage("mainImage");
                                } else {
                                  removeImage("mainImage", productData._id, productData.mainImage);
                                }
                              }}
                            >
                              <i className="bx bx-x font-size-22"></i>
                            </Button>
                          </div>
                        </div>
                        : null
                      }

                      <Button
                        type="button"
                        color={`primary`}
                        className={`px-4 ${values.mainImage ? "mt-3" : ""}`}
                        disabled={productData && productData.mainImage}
                        onClick={(event) => {
                          MainService.triggerUploadClick(event);
                        }}
                      >
                        Upload main image
                        <input type="file" id='avatarImage' className="d-none" hidden multiple onChange={(event) => uploadFile(event, "mainImage")} />
                      </Button>
                    </Col>
                    {
                      !values.parent || (productData && productData.backgroundImage) ?
                        <Col md={6} className="mt-2">
                          {values?.backgroundImage?.uploadedFile || productData?.backgroundImage ?
                            <div>
                              <div className='position-relative d-inline-block'>
                                <img
                                  src={values.backgroundImage?.uploadedFile ? values.backgroundImage.uploadedFile : productData && productData.backgroundImage ? serverImgURL(productData.backgroundImage) : ""}
                                  alt="/"
                                  style={{ height: "200px" }}
                                  className="img-thumbnail"
                                />
                                <Button
                                  style={{ position: "absolute", top: 5, right: 5 }}
                                  color="danger"
                                  className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    if (values.backgroundImage && values.backgroundImage.uploadedFile) {
                                      removeUploadedImage("backgroundImage")
                                    } else {
                                      removeImage("backgroundImage", productData._id, productData.backgroundImage);
                                    }
                                  }}
                                >
                                  <i className="bx bx-x font-size-22"></i>
                                </Button>
                              </div>
                            </div>
                            : null
                          }
                          <Button
                            type="button"
                            color={`primary`}
                            className={`px-4 ${values.backgroundImage ? "mt-3" : ""}`}
                            disabled={productData && productData.backgroundImage}
                            onClick={(event) => {
                              MainService.triggerUploadClick(event);
                            }}
                          >
                            Upload cover image (1500x300)
                            <input type="file" id='avatarImage' className="d-none" hidden multiple onChange={(event) => uploadFile(event, "backgroundImage")} />
                          </Button>
                        </Col>
                        : null
                    }
                  </Row>
                </div>
              </Col>
              <Col md={12}>
                {
                  fileReadPercent > 0 && fileReadPercent < 100 ?
                    <div className="col-12">
                      <ProgressBar now={fileReadPercent} />
                    </div>
                    : null
                }
              </Col>
              <Col md={12}>
                <hr />
                <div className='d-flex justify-content-end'>
                  <ActionButton
                    type="submit"
                    name="Save"
                    color="primary"
                    disabled={!changes}
                    spinnerClass={`w_50`}
                    className="btn btn-primary btn-block px-4"
                    spinnerId={buttonSpinnerId}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(CategoryForm)