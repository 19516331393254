// import React from "react"
// import { useSelector } from 'react-redux'
// import { BrowserRouter as Router } from "react-router-dom"
// import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
// import NonAuthLayout from "./components/NonAuthLayout"
// import PageSpinner from './components/Spinners/PageSpinner'
// import "./assets/scss/theme.scss"
// import UnauthMiddleware from "./routes/middleware/UnauthMiddleware"
// import AuthMiddleware from "./routes/middleware/Authmiddleware"
// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import OrdersComponent from "./OrdersComponent"

// const App = () => {

//   const { user } = useSelector(state => state.Login);
//   const { pageSpinners } = useSelector(state => state.Spinners);
//   const layout = useSelector(state => state.Layout);

//   const getLayout = () => {
//     let layoutCls = VerticalLayout
//     switch (layout.layoutType) {
//       case "horizontal":
//         layoutCls = HorizontalLayout
//         break
//       default:
//         layoutCls = VerticalLayout
//         break
//     }
//     return layoutCls
//   }

//   const Layout = getLayout();

//   return (
//     <React.Fragment>
//       <Router>

//         {
//           user ?
//             <Layout>
//               <AuthMiddleware />
//             </Layout>
//             :
//             <NonAuthLayout>
//               <UnauthMiddleware />
//             </NonAuthLayout>
//         }
//         <OrdersComponent />
//       </Router>
//       <PageSpinner spinner={pageSpinners} />
//       <ToastContainer
//         theme="dark"
//         autoClose={5000}
//       />
//     </React.Fragment>
//   )
// }

// export default App;
import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import PageSpinner from './components/Spinners/PageSpinner'
import { addPageSpinner, removePageSpinner } from './store/spinner/actions'
import "./assets/scss/theme.scss"
import UnauthMiddleware from "./routes/middleware/UnauthMiddleware"
import AuthMiddleware from "./routes/middleware/Authmiddleware"
import { io } from "socket.io-client"
import { API_URL_KEY } from "./constants/apiKeys"
import { withRouter } from "react-router-dom"
import { setConnection } from "./store/connection/actions"
import AlertService from "./services/alertService"
import Audio from "./components/Audio/Audio"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NewOrderNotificationModal from "./NewOrderNotificationModal"

export const socket = io(API_URL_KEY, { transports: ['websocket'] });

const App = (props) => {

  const dispatch = useDispatch();
  const { history, location } = props;
  const { user } = useSelector(state => state.Login);
  const { pageSpinners } = useSelector(state => state.Spinners);
  const layout = useSelector(state => state.Layout);
  const [playAudio, setPlayAudio] = useState(false);
  const [isShowNewOrderNotificationModal, setIsShowNewOrderNotificationModal] = useState(false);

  useEffect(() => {
    dispatch(setConnection(socket))
  }, [socket])

  useEffect(() => {

    if (user) {
      socket.emit(
        "getNotifications",
        { id: null, isAdmin: true },
        (data) => {
          // dispatch(HomeActions.setNots(data));
        }
      );

      socket.on("newNotification", onGetNot);
    }

    return () => {
      socket.off("newNotification");
    };
  }, [user]);

  const reload = () => {
    window.location.reload()
  }

  const onGetNot = useCallback(
    (data) => {

      const titles = {
        "new-order": "You have new order!",
        "new-message": "You have new message!",
      };

      if (data && data.type === "new-message") {
        if (!window.location.pathname.includes("/chat")) {
          AlertService.alert("info", titles[data.type], `/chat/${data.userId}`);
        } else {
          AlertService.alert("info", titles[data.type]);
        }
      }

      if (data && data.type === "new-order") {
        setIsShowNewOrderNotificationModal(true);
      }

      playRingtone();

    }, [location]
  );

  const playRingtone = () => {
    setPlayAudio(true);
    setTimeout(() => setPlayAudio(false), 1000);
  };

  const getLayout = () => {
    let layoutCls = VerticalLayout
    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const Layout = getLayout();

  const onCloseNewOrderNotificationModal = (bool = false) => {
    setIsShowNewOrderNotificationModal(bool);
    if (!window.location.pathname.includes("/orders")) {
      props.history.push(`/orders`)
    }
    reload()
  }

  return (
    <React.Fragment>
      <Router>
        <NewOrderNotificationModal
          show={isShowNewOrderNotificationModal}
          cb={onCloseNewOrderNotificationModal}
        />
        {
          user ?
            <Layout>
              <AuthMiddleware />
            </Layout>
            :
            <NonAuthLayout>
              <UnauthMiddleware />
            </NonAuthLayout>
        }
      </Router>
      <PageSpinner spinner={pageSpinners} />
      <ToastContainer
        theme="dark"
        autoClose={5000}
      />
      <Audio play={playAudio} />
    </React.Fragment>
  )
}

export default withRouter(App) 