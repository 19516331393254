// import React, { useEffect } from "react";
// import messageRingtone2 from "./../../assets/audio/message.mp3";

// const Audio = ({ play }) => {

//   const audioStart = () => {
//     var audioElement = document.getElementById("audio");
//     if (audioElement) {
//       audioElement.src = messageRingtone2;
//       audioElement.autoplay = true;
//     }
//   };

//   useEffect(() => {
//     if (play) {
//       try {
//         audioStart();
//       } catch (error) {
//         setTimeout(() => {
//           audioStart();
//         }, 1000);
//       }
//     }
//   }, [play]);

//   return <audio id="audio" className="d-none" loop />;
// };

// export default Audio;


import React, { useEffect } from "react";
import messageRingtone2 from "./../../assets/audio/message.mp3";

const Audio = ({ play }) => {

  useEffect(() => {
    const audioElement = document.getElementById("audio");
    
    const audioStart = () => {
      if (audioElement) {
        audioElement.src = messageRingtone2;
        audioElement.autoplay = true;
      }
    };

    if (play) {
      try {
        audioStart();
      } catch (error) {
        setTimeout(() => {
          audioStart();
        }, 1000);
      }
    }
  }, [play]);

  return <audio id="audio" className="d-none" loop />;
};

export default Audio;
