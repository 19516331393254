import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import messageRingtone2 from "./assets/audio/message.mp3";
import Audio from './components/Audio/Audio';

function NewOrderNotificationModal({ show, cb }) {

    const toggle = () => cb(false);

    return (
        <div>
            <Modal isOpen={show} centered>
                <ModalBody>
                    {/* <audio autoPlay loop>
                        <source src={messageRingtone2} accessKey='audio' />
                    </audio> */}
                    <Audio play={show} />
                    <div className='d-flex justify-content-center mb-4 mt-3'>
                        <span className="bell fa fa-bell"></span>
                    </div>
                    <h3 className='text-center  mb-0'>New Order</h3>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-center'>
                    <Button color="success" onClick={toggle}>
                        View order
                    </Button>
                    <Button color="secondary" onClick={toggle} className='px-4'>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewOrderNotificationModal;
