import {
  SET_ORDERS,
  SET_ORDERS_COUNT,
  SET_ORDER_SEARCH,
  SET_PENDING_ORDER_IDS,
  SET_DATE_FROM,
  SET_DATE_TO
} from "./actionTypes"

export const setOrders = orders => ({
  type: SET_ORDERS,
  payload: orders,
})

export const setOrdersCount = count => ({
  type: SET_ORDERS_COUNT,
  payload: count,
})

export const setOrderSearch = text => ({
  type: SET_ORDER_SEARCH,
  payload: text,
})

export const setPendingOrderIds = pendingOrderIds => ({
  type: SET_PENDING_ORDER_IDS,
  payload: pendingOrderIds,
})

export const setDateFrom = date => ({
  type: SET_DATE_FROM,
  payload: date,
})

export const setDateTo = date => ({
  type: SET_DATE_TO,
  payload: date,
})