import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { AiOutlineArrowRight } from "react-icons/ai";

const getAlertType = (type, cb, error, redirectUrl, cbFunc) => {
  if (!type) { cb.error(error) };
  switch (type) {
    case 'info':
      if (redirectUrl || cbFunc) {
        return cb(<div>
          {error}
          <button
            className='react-toastify-more-button'
            onClick={(event) => {
              event && event.stopPropagation();
              if (redirectUrl) window.location.replace(redirectUrl);
              else cbFunc();
            }}
          >
            More
            <AiOutlineArrowRight className='ms-1' size={14} />
          </button>
        </div>
          , { position: toast.POSITION.TOP_RIGHT, type: toast.TYPE.INFO })
      } else {
        return cb.info(error, { position: toast.POSITION.TOP_RIGHT });
      }
    case 'success':
      if (redirectUrl || cbFunc) {
        return cb(<div>
          {error}
          <button
            className='react-toastify-more-button'
            onClick={(event) => {
              event && event.stopPropagation();
              if (redirectUrl) window.location.replace(redirectUrl);
              else cbFunc();
            }}
          >
            More
          </button>
        </div>
          , { position: toast.POSITION.TOP_RIGHT, type: toast.TYPE.SUCCESS })
      } else {
        return cb.success(error, { position: toast.POSITION.TOP_RIGHT });
      }
    case 'warning':
      if (redirectUrl || cbFunc) {
        return cb(<div>
          {error}
          <button
            className='react-toastify-more-button'
            onClick={(event) => {
              event && event.stopPropagation();
              if (redirectUrl) window.location.replace(redirectUrl);
              else cbFunc();
            }}
          >
            More
          </button>
        </div>
          , { position: toast.POSITION.TOP_RIGHT })
      } else {
        return cb.warning(error, { position: toast.POSITION.TOP_RIGHT, type: toast.TYPE.WARNING });
      }
    case 'error':
      if (redirectUrl || cbFunc) {
        return cb(<div>
          {error}
          <button
            className='react-toastify-more-button'
            onClick={(event) => {
              event && event.stopPropagation();
              if (redirectUrl) window.location.replace(redirectUrl);
              else cbFunc();
            }}
          >
            More
          </button>
        </div>
          , { position: toast.POSITION.TOP_RIGHT })
      } else {
        return cb.error(error, { position: toast.POSITION.TOP_RIGHT, type: toast.TYPE.ERROR });
      }
    default:
      break;
  }
}

class AlertService {

  static alert = (type, error, redirectUrl, cb) => {
    const respMessage = typeof error === "object" ? error.message || error.respmess : error;
    if (!respMessage) { return false; }
    getAlertType(type, toast, respMessage, redirectUrl, cb);
  }

  static checkMessageType = (respcode) => {
    switch (respcode) {
      case 0:
        return 'success'
      case 1:
        return 'error'
      case 2:
        return 'warning'
      default:
        break;
    }
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              {title ? <strong className='font-size-18'>{title}</strong> : null}
              {message ? <p className='mt-4'>{message}</p> : null}
              <hr />
              <a href="#" className="btn btn-lg btn-success capitailze" onClick={() => { reject(); onClose(); }}>{no}</a>
              <a href="#" className="btn btn-lg btn-danger capitailze" onClick={() => { resolve(); onClose(); }}>{yes}</a>
            </div>
          );
        }
      })
    })
  }

}


export default AlertService;