export const TOKEN_KEY = 'token';
export const LANGUAGE_KEY = 'language';
export const FORM_LANGUAGE_KEY = 'formLanguage';
export const LANGUAGES_KEY = 'languages';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TRANSLATE_KEYS = 'translatekeys';
export const OBJECT_KEY = 'object';
export const INFO_KEY = "info";
export const ERROR_KEY = 'error';
export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";
export const NUMBER_KEY = "number";
export const SPINNER_COLOR = "#1081E8";
export const MAXIMUM_UPLOAD_FILE_SIZE = 3; //MB


export const IMAGE_KEY = 'image';
export const FILE_KEY = 'file';
export const VIDEO_KEY = 'video';

export const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG', 'svg', 'SVG'];
export const VALID_VIDEO_TYPES_KEY = ['mp4'];
export const ALL_VALID_FILE_TYPES = ['jpeg', 'png', 'jpg', 'mp3', 'mp4', 'mpg', 'avi', 'pdf', 'txt', 'doc', 'rtf', 'xls', 'xlsx', 'docx', 'pptx'];
export const PAGE_NUMBER = 10;
export const fieldValidation = {
  length_50: 50,
  length_100: 100,
  length_255: 255,
  length_2000: 2000,
}

