import moment from "moment";
import React from "react";


const getPaymentType = (paymentType) => {
  switch (paymentType) {
    case "cash":
      return "Cash"
    case "bank_card":
      return "Bank Card"
    case "idram":
      return "IDram"
    default:
      break;
  }
}

const OrderInvoiceTemplate = ({
  _id,
  createdAt,
  products,
  totalAmount,
  user,
  payment,
  address,
  deliveryPrice,
  regions,
  cities
}) => {
  const styles = {
    container: {
      fontFamily: "Armenian, Arial, sans-serif",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
      maxWidth: "90%"
    },
    heading: {
      fontSize: "24px",
      marginBottom: "10px",
    },
    info: {
      marginBottom: "10px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "10px",
    },
    tableHeader: {
      background: "#f2f2f2",
      borderBottom: "1px solid #ccc",
    },
    tableHeaderCell: {
      padding: "8px",
      fontWeight: "bold",
      textAlign: "start"
    },
    tableCell: {
      padding: "8px",
      borderBottom: "1px solid #ccc",
    },
    total: {
      textAlign: "right",
      fontSize: "20px",
      fontWeight: "bold",
    },
    delivery: {
      textAlign: "right",
      fontSize: "18px",
      fontWeight: "500",
    },
  };

  const getRegion = (region, regions) => {
    if (regions && regions.length && region) {
      let currentItem = regions.find(item => item._id === region);
      if (currentItem && currentItem.name) {
        return currentItem.name?.en
      } else {
        return region || "";
      }
    } else {
      return region || "";
    }
  }

  const getCity = (city, cities) => {
    if (cities && cities.length && city) {
      let currentItem = cities.find(item => item._id === city);
      if (currentItem && currentItem.name) {
        return currentItem.name?.en
      } else {
        return city || "";
      }
    } else {
      return city || "";
    }
  }


  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Order</h2>
      <p style={styles.info}>Order ID: {_id}</p>
      <p style={styles.info}>Date: {moment(new Date(createdAt)).format("LL HH:mm")}</p>
      {
        user ?
          <p style={styles.info}>Order Owner: {user.firstName} {user.lastName}</p>
          :
          <p style={styles.info}>Order Owner: Unknown client</p>
      }
      <p style={styles.info}>Payment Method: {getPaymentType(payment?.paymentType)}</p>
      <p style={styles.info}>Province: {getRegion(address?.province, regions)}</p>
      <p style={styles.info}>City: {getCity(address?.city, cities)}</p>
      <p style={styles.info}>Address: {address?.address}</p>
      <p style={styles.info}>Phone Number: {address?.phoneNumber}</p>

      <table style={styles.table}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={styles.tableHeaderCell}>Products</th>
            <th style={styles.tableHeaderCell}>Quantity</th>
            <th style={styles.tableHeaderCell}>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map(({ product, count, price }) => {
            if (product) {
              return <tr key={product._id}>
                <td style={styles.tableCell}>{product._title?.en || ""}</td>
                <td style={styles.tableCell}>{count}</td>
                <td style={styles.tableCell}>{price * count} {`AMD`}</td>
              </tr>
            } else {
              return false
            }
          })}
        </tbody>
      </table>
      <div style={styles.delivery}>
        <p>Delivery Price: {deliveryPrice ? deliveryPrice : 0} AMD</p>
      </div>
      <div style={styles.total}>
        <p>Total: {totalAmount} AMD</p>
      </div>
    </div>
  );
};

export default OrderInvoiceTemplate;
