import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import "./Css/main.css"
import { Provider } from "react-redux"
import "react-confirm-alert/src/react-confirm-alert.css"
import store from "./store"


// import { createRoot } from 'react-dom/client';
// const root = createRoot(document.getElementById('root'));

// root.render(<Provider store={store}>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// </Provider>);


const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
