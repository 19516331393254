import axios from 'axios';
import AlertService from './alertService';
import { API_URL_KEY } from '../constants/apiKeys';
import { ERROR_KEY, OBJECT_KEY } from '../constants/mainKeys';

const api = axios.create({
  baseURL: API_URL_KEY,
  // withCredentials: true,
});

api.interceptors.request.use(async config => {
  // config.headers = {
  //   'Authorization': localStorage.getItem("token") ? localStorage.getItem("token") : "",
  //   'language': localStorage.getItem(LANGUAGE_KEY),
  // };
  config.headers.Authorization = `Bearer ${localStorage.getItem("token") ? localStorage.getItem("token") : ""}`;
  return config;
});


api.interceptors.response.use(response => {

  if (response && response.data && response.data?.status >= 500) {
    if (response.data?.message) {
      return Promise.reject(response.data?.message);
    }
    return Promise.reject("Sorry, something went wrong!");
  }

  if (response && response.data && response.data.status !== 200) {
    const currentError = response.data;

    //if typeof error === string
    // if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
    if (currentError && currentError.message && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length && !Object.keys(currentError.errors).length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          return Promise.reject(err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (currentError.errors && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]?.key);
    }
  }

  if (response && response.data) {
    if (response.data.respmess === 'User is not Authenticated') {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = `/`;
    }
    return !response.data.respcode ? response.data : Promise.reject({ respmess: response.data.respmess, respcode: response.data.respcode });
  }
}, error => {
  if (error.response && error.response.status === 500) {
    if (error.response.data && error.response.data.message) {
      return Promise.reject(error.response.data.message);
    }
    return Promise.reject("Internal Server Error");
  }
  if (error.response && error.response.status === 400) {
    return Promise.reject("Bad Request Exception");
  }
  if (error.response && error.response.status === 404) {
    return Promise.reject("404 Method not found");
  }
  if (error.response && error.response.status === 401) {
    logout();
  }
  if (error.response) {
    const currentError = error.response.data;

    //if typeof error === string
    if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          AlertService.alert(ERROR_KEY, err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (typeof currentError.errors === OBJECT_KEY && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]);
    }
  } else {
    return Promise.reject();
  }
});

const logout = () => {
  window.location.href = `/login`;
  localStorage.clear();
}

class ApiService {

  static getDashboardData() {
    return api.get(`/dashboard`);
  };

  static login(data) {
    return api.post(`/auth/login/admin`, data);
  };

  static getBrands() {
    return api.get(`/brands/all`);
  };

  static getCategoriesList() {
    return api.get(`/categories/list`);
  };

  static getCategories() {
    return api.get(`/categories`);
  };

  static getProducts(data) {
    return api.post(`/products/all`, data);
  };

  static getProductById(productId) {
    return api.get(`/products/${productId}`);
  };

  static createProduct(data) {
    return api.post(`/products`, data);
  };

  static updateProduct(productId, data) {
    return api.put(`/products/${productId}`, data);
  };

  static updateCategory(categoryId, data) {
    return api.put(`/categories/${categoryId}`, data);
  };
  
  static createCategory(data) {
    return api.post(`/categories`, data);
  };

  static deleteProduct(productId) {
    return api.delete(`/products/${productId}`);
  };

  static deleteCategory(categoryId) {
    return api.delete(`/categories/${categoryId}`);
  };

  static getCategoryById(categoryId) {
    return api.get(`/categories/${categoryId}`);
  };

  static getUsers(page, limit, status, sortBy, orderBy) {
    return api.get(`/users?page=${page}&limit=${limit}&status=${status}&sortBy=${sortBy}&orderBy=${orderBy}`);
  };

  static blockStudent(userId) {
    return api.put(`/users/${userId}/block-unblock`);
  };

  static deleteBrand(brandId) {
    return api.delete(`/brands/${brandId}`);
  };

  static createBrand(data) {
    return api.post(`/brands`, data);
  };

  static updateBrand(brandId, data) {
    return api.put(`/brands/${brandId}`, data);
  };

  static setContactUsPageData() {
    return api.get(`/contacts`);
  };

  static createContactUs(data) {
    return api.post(`/contacts`, data);
  };

  static setAboutUsPageData() {
    return api.get(`/about`);
  };

  static createAboutUs(data) {
    return api.post(`/about`, data);
  };

  static getOrders(pageNumber, pageSize, status, search, dateFrom = "", dateTo = "") {
    return api.get(`/orders/all?page=${pageNumber}&limit=${pageSize}&status=${status}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
  };

  static updateOrderStatus(orderId, status) {
    return api.put(`/orders/${orderId}/status/${status}`);
  };

  static getConfigs() {
    return api.get(`/configs/all`);
  };

  static updateConfig(id, data) {
    return api.put(`/configs/${id}`, data);
  };

  static getPaymentSystems() {
    return api.get(`/paymentSystems/all`);
  };

  static updatePaymentSystem(id, data) {
    return api.put(`/paymentSystems/${id}`, data);
  };

  static getLibraries() {
    return api.get(`/libraries/all`);
  };

  static updateLibrary(id, data) {
    return api.put(`/libraries/${id}`, data);
  };

  static changeCategoryDisplayOrder(dropId, dragId) {
    return api.put(`/categories/displayOrder/${dropId}/${dragId}`);
  };

  static changeProcuctDisplayOrder(dropId, dragId) {
    return api.put(`/products/displayOrder/${dropId}/${dragId}`);
  };

  static refund(orderId, paymentId, amount) {
    return api.post(`/payment/ameria/refundPayment/${orderId}/${paymentId}/${amount}`);
  };

  static removeImage(type, id, imageName) {
    return api.put(`/${type}/removeImage/${id}/${imageName}`);
  };

  static getRegions() {
    return api.get(`/shippingLocations/parents`);
  };

  static getCities() {
    return api.get(`/shippingLocations/children`);
  };

  static updateShippingLocation(locationId, data) {
    return api.put(`/shippingLocations/${locationId}`, data);
  };

  static createShippingLocation(data) {
    return api.post(`/shippingLocations`, data);
  };

  static deleteShippingLocation(locationId) {
    return api.delete(`/shippingLocations/${locationId}`);
  };

  static getShippingLocationById(locationId) {
    return api.get(`/shippingLocations/${locationId}`);
  };


}

export default ApiService;