import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import { MetaTags } from 'react-meta-tags'
import { withRouter } from "react-router-dom"


const buttonSpinnerId = uuid();
const Dashboard = (props) => {

  const { history } = props;

  const dispatch = useDispatch()
  const [data, setData] = useState([]);

  useEffect(() => {
    getDashboardData();
    return () => {
      setData([])
    }
  }, [])

  const getDashboardData = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getDashboardData().then(response => {
      if (response && response.data) {
        setData(response.data)
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Dashboard"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        {data && data.length ?
          <Card>
            <CardBody>
              <Row>
                {
                  data.map((item, index) => {
                    return <Col key={index} md={6} className=''>
                      <div
                        className={`d-flex justify-content-between align-items-center rounded shadow p-3 my-2 cursor-pointer border border-left-${item.border}`}
                        onClick={() => history.push(item.link)}
                      >
                        <div>
                          <h4 className='m-0'>{item.text}</h4>
                        </div>
                        <div>
                          <i className={item.icon} style={{ fontSize: "30px" }}></i>
                        </div>
                      </div>
                    </Col>
                  })
                }
              </Row>
            </CardBody>
          </Card>
          : <NoData />}
      </div>
    </div>
  )
}
export default withRouter(Dashboard)
