import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY, PAGE_NUMBER } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Card, CardBody, Label } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import moment from 'moment'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { MetaTags } from 'react-meta-tags'

const buttonSpinnerId = uuid();
export default function Users(props) {

  const dispatch = useDispatch()
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  const statuses = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Not Verified",
      value: "not-verified",
    },
    {
      label: "Is Blocked",
      value: "blocked",
    },
  ]

  const [values, setValues] = useState({
    page: 0,
    limit: PAGE_NUMBER,
    status: "",
    sortBy: "createdAt",
    orderBy: -1,
  });

  useEffect(() => {
    getUsers();
    return () => {
      setUsers([])
    }
  }, [values])


  const handlePageClick = (event) => {
    setValues((items) => ({
      ...items,
      page: event.selected
    }))
  };

  const getUsers = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getUsers(values.page, values.limit, values.status, values.sortBy, values.orderBy).then(response => {
      if (response && response.data) {
        if (response.data.users) setUsers(response.data.users);
        if (response.data.usersCount) setUsersCount(response.data.usersCount);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const blockStudent = (userData) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      ApiService.blockStudent(userData._id).then(response => {
        getUsers()
        AlertService.alert("success", "Data saved");
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [fieldName]: item.value
    }))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Users</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Users"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row>
          <Col md={6} className='mb-3'>
            <Label>Filter by status</Label>
            <ReactSelectOption
              value={values.status}
              selectedValue={(() => {
                const selectedValue = { ...statuses.find(data => data.value === values.status) };
                if (Object.keys(selectedValue).length) {
                  selectedValue.label = selectedValue.label;
                  selectedValue.value = selectedValue.value;
                }
                return selectedValue;
              })()}
              className={`rounded`}
              items={statuses.map(data => ({ label: data.label, value: data.value }))}
              onChange={item => onSelectOptionChange(item, "status")}
            />
          </Col>
          {
            users && users.length ?
              <Col sm={12}>

                <Table bordered responsive className="text-center">
                  <thead>
                    <tr>
                      <th className="align-middle">#</th>
                      <th className="align-middle">Name</th>
                      <th className="align-middle">Email</th>
                      <th className="align-middle">Register Date</th>
                      <th className="align-middle">Phone Number</th>
                      <th className="align-middle">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((item, index) => (
                      <tr key={`${item._id}`}>
                        <td className="align-middle"><b>{index + 1}</b></td>
                        <td className="align-middle">
                          {
                            item.firstName || item.lastName ?
                              <b>{item.firstName ? item.firstName : ""} {item.lastName ? item.lastName : ""}</b> :
                              <b>Unknown client</b>
                          }
                        </td>
                        <td className="align-middle">{item.email ? item.email : "Unknown client"}</td>
                        <td className="align-middle">{moment(new Date(item.createdAt)).format("ll")}</td>
                        <td className="align-middle">
                          {
                            item.telephone ? (
                              item.telephone.startsWith("374") ? (
                                <a href={`tel:+${item.telephone}`}>+{item.telephone}</a>
                              ) : (
                                <a href={`tel:+374${item.telephone}`}>+374{item.telephone}</a>
                              )
                            ) : null
                          }
                        </td>
                        <td className="align-middle">
                          <div
                            className="d-flex justify-content-center gap-1"
                          >
                            <ActionButton
                              type="button"
                              name={item.privateInfo.isBlock ? "Unblock" : "Block"}
                              color={item.privateInfo.isBlock ? "success" : "danger"}
                              spinnerClass={`w_50`}
                              spinnerId={`${buttonSpinnerId}_${index}`}
                              onClick={() => blockStudent(item, `${buttonSpinnerId}_${index}`)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {
                  usersCount && usersCount > PAGE_NUMBER ?
                    <div className=' mt-4 d-flex justify-content-end w-100'>
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(usersCount / PAGE_NUMBER)}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={values.page}
                      />
                    </div>
                    : null
                }
              </Col>
              : <NoData />
          }
        </Row>
      </div>
    </div>
  )
}
