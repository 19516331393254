import PropTypes from "prop-types"
import React, { useState } from "react"
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"
import { withTranslation } from "react-i18next"

import { connect, useDispatch, useSelector } from "react-redux"
import { toggleLeftmenu } from "../../store/actions"

const Navbar = props => {

  const dispatch = useDispatch()
  const [dashboard, setdashboard] = useState(false);
 
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse w-100"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav horizontal-scrollbar w-100">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to={`/dashboard`}
                    onClick={() => dispatch(toggleLeftmenu(false))}
                  >
                    <i className='bx bxs-dashboard me-2'></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setdashboard(!dashboard)
                    }}
                    to="/#"
                  >
                    <i className='bx bxs-bank me-2'></i>
                    {props.t("Page")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: dashboard })}
                  >
                    <Link to={`/pages`} className="dropdown-item" onClick={() => dispatch(toggleLeftmenu(false))}>Pages</Link>
                  </div>
                </li>

                

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
