import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ActionButton from '../../../components/Buttons/ActionButton'
import MetaTags from 'react-meta-tags'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const buttonSpinnerId = uuid();
export default function Configs(props) {

  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [values, setValues] = useState({
    key: "",
    value: "",
  })

  const serviceAvailabilityItems = [
    {
      value: "0",
      label: "No"
    },
    {
      value: "1",
      label: "Yes"
    }
  ]

  useEffect(() => {
    getConfigs();
    return () => {
      setData([])
    }
  }, [])

  const getConfigs = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getConfigs().then(response => {
      if (response && response.data && response.data) {
        setData(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const cancel = () => {
    setValues(values => ({ ...values, key: "", value: "", }));
    setSelectedField(null);
  }

  const getLabel = (item) => {
    if (!item) return "Value";
    switch (item.key) {
      case "working_hours":
        return "Working Hours Ex. 09:00-23:00"
      case "free_shipping":
        return "Enter this amount, after which delivery will be free."
      case "shipping":
        return "Default shipping price."
      default:
        return "Value"
    }
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [fieldName]: item.value
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    let newData = [...data];
    newData.unshift(values);
    setButtonSpinner(buttonSpinnerId);
    values.value = values.value.trim();
    ApiService.updateConfig(selectedField._id, values).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      cancel();
      getConfigs();
      AlertService.alert("success", "Data saved");
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Configs</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Configs"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row>
          {
            data && data.length ?
              <Col sm={12}>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th className="align-middle">#</th>
                      <th className="align-middle">Key</th>
                      <th className="align-middle">Value</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={`${index}`}>
                        <td className="align-middle"><b>{index + 1}</b></td>
                        <td className="align-middle">{item.key}</td>
                        {
                          item.key === "service_availability" ?
                            <td className="align-middle">{item.value == "1" ? "Yes" : "No"}</td>
                            : <td className="align-middle">{item.value}</td>
                        }
                        <td className="align-middle">
                          <div
                            className="d-flex justify-content-center gap-1"
                          >
                            <Button
                              color="warning"
                              onClick={() => {
                                setSelectedField(item);
                                setValues({
                                  key: item.key,
                                  value: item.value
                                })
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              : <NoData />
          }
        </Row>
      </div>
      {
        selectedField ?
          <Modal
            show={true}
            onHide={cancel}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="field">
                  <Form.Label htmlFor='key'>Key</Form.Label>
                  <Form.Control
                    id='key'
                    type="text"
                    placeholder="Key"
                    disabled
                    value={values.key}
                    onChange={(event) => { }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="field">
                  <Form.Label htmlFor='value'>
                    {getLabel(selectedField)}
                  </Form.Label>
                  {
                    selectedField.key === "service_availability" ?
                      <ReactSelectOption
                        value={values.value}
                        selectedValue={(() => {
                          const selectedValue = { ...serviceAvailabilityItems.find(data => data.value === values.value) };
                          if (Object.keys(selectedValue).length) {
                            selectedValue.label = selectedValue.label;
                            selectedValue.value = selectedValue.value;
                          }
                          return selectedValue;
                        })()}
                        className={`rounded`}
                        items={serviceAvailabilityItems.map(data => ({ label: data.label, value: data.value }))}
                        onChange={item => onSelectOptionChange(item, "value")}
                      />
                      : <Form.Control
                        id='value'
                        type="text"
                        autoFocus
                        placeholder="value"
                        value={values.value}
                        onChange={(event) => {
                          setValues(values => ({
                            ...values,
                            value: event.target.value
                          }))
                        }}
                      />
                  }

                </Form.Group>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="secondary"
                onClick={cancel}
              >
                Close
              </Button>
              <ActionButton
                type="button"
                name="Save"
                color="primary"
                spinnerClass={`w_50`}
                spinnerId={buttonSpinnerId}
                disabled={!values.value.trim().length}
                onClick={onSubmit}
              />
            </Modal.Footer>
          </Modal>
          : null
      }
    </div>
  )
}
