import { call, put, takeLatest } from "redux-saga/effects"
import { AUTH_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS, SOCIAL_LOGIN } from "./actionTypes"
import { apiError } from "./actions"
import AlertService from "../../../services/alertService"
import { addButtonSpinner, removeButtonSpinner } from './../../spinner/actions';
import ApiService from "../../../services/apiService"
import { TOKEN_KEY, USER_KEY } from "../../../constants/mainKeys";


function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeLatest(LOGOUT_USER, logoutUser)
}

function* loginUser(action) {
  const { spinnerId, history } = action.payload;
  try {
    yield put(addButtonSpinner(spinnerId));
    const formData = { ...action.payload.data };
    const data = yield call(ApiService.login, formData);
    if (data) {
      yield put({
        type: AUTH_SUCCESS,
        payload: data,
      });
    }
    yield put(removeButtonSpinner(spinnerId));
    history.push(`/dashboard`);
  } catch (error) {
    error && AlertService.alert("error", error);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
    yield put(removeButtonSpinner(spinnerId));
  };
};


function* logoutUser({ payload: { history } }) {
  try {
    yield put({
      type: LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) { }


export default authSaga
