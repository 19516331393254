import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody, Input, Label } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ActionButton from '../../../components/Buttons/ActionButton'
import MetaTags from 'react-meta-tags'

const buttonSpinnerId = uuid();
export default function Configs(props) {

  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [changes, setChanges] = useState(false);

  const [values, setValues] = useState({
    name: "",
    value: "",
    libraryData: [
      {
        name: "",
        value: ""
      }
    ]
  })

  useEffect(() => {
    getLibraries();
    return () => {
      setData([])
    }
  }, [])

  const getLibraries = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getLibraries().then(response => {
      if (response && response.data && response && response.data.length) {
        setValues(response.data.find(item => item.name === "set_config"));
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    ApiService.updateLibrary(values._id, values).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      cancel();
      getLibraries();
      AlertService.alert("success", "Data saved");
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const cancel = () => {
    setValues(values => ({ ...values, key: "", value: "", }));
    setSelectedField(null);
  }

  const onBenefitsChange = (event, index, name, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const newBenefits = { ...values };
    newBenefits.libraryData[index][name] = event.target.value;
    setValues(newBenefits);
  }

  const removeBenefit = (index) => {
    let newValues = { ...values };
    newValues.libraryData = newValues.libraryData.filter((_, i) => i !== index);
    setValues(newValues);
    setChanges(true);
  };

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Library</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Library"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Form
          className='mb-3'
          onChange={() => setChanges(true)}
          onSubmit={onSubmit}
        >
          <div className='border rounded p-2 mb-3'>
            <Input
              type="text"
              className={`form-control mt-1 mb-2`}
              placeholder="Ex. Benefits (key)"
              disabled
              value={values.name}
              onChange={(event) => { }}
            />
            {
              values.libraryData.length ?
                <Col lg={12}>
                  {
                    values.libraryData.map((item, index) => {
                      return <Row key={index} className='rounded p-2'>
                        <Col md={6}>
                          <small>Price</small>
                          <Input
                            type="number"
                            className={`form-control mt-1`}
                            placeholder="Ex. 10000"
                            value={item.name}
                            onChange={(event) => onBenefitsChange(event, index, "name")}
                          />
                        </Col>
                        <Col md={6}>
                          <div>
                            <small>Discount</small>
                            <div className='d-flex align-items-center'>
                              <Input
                                type="number"
                                className={`form-control mt-1`}
                                placeholder="Ex. 1000"
                                value={item.value}
                                onChange={(event) => onBenefitsChange(event, index, "value")}
                              />
                              <Button
                                color="danger"
                                outline
                                type='button'
                                className="btn-sm p-0 d-flex justify-content-center align-items-center ms-2"
                                onClick={() => {
                                  removeBenefit(index)
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>

                          </div>

                        </Col>

                      </Row>
                    })
                  }
                  <div className='d-flex justify-content-end px-2 mt-2'>
                    <Button
                      color="primary"
                      type='button'
                      outline
                      className="btn-sm d-flex justify-content-center align-items-center"
                      onClick={() => {
                        const newValues = { ...values };
                        newValues.libraryData.push({
                          name: "",
                          value: ""
                        });
                        setValues(newValues);
                      }}
                    >
                      <span className='font-size-18 me-1'>+</span>
                      <span className='font-size-12 me-1'>Add field</span>
                    </Button>
                  </div>
                </Col>
                : null
            }

          </div>

          <div className='d-flex justify-content-end '>
            <ActionButton
              type="button"
              name="Save"
              color="primary"
              className="px-4"
              spinnerClass={`w_50`}
              spinnerId={buttonSpinnerId}
              onClick={onSubmit}
            />
          </div>
        </Form>
      </div>

    </div>
  )
}
