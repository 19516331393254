import moment from "moment";
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Table, Modal } from "react-bootstrap"
import { serverImgURL } from "../../../helpers";

const OrderView = ({
    order,
    cancel,
    showModal,
    regions,
    cities
}) => {

    const formatCurrency = (number, decimalPlaces = 0) => {
        return Number(number).toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    }

    const getPaymentType = (paymentType) => {
        switch (paymentType) {
            case "cash":
                return "Cash"
            case "bank_card":
                return "Bank Card"
            case "idram":
                return "IDram"
            default:
                break;
        }
    }

    const getRegion = (region, regions) => {
        if (regions && regions.length && region) {
            let currentItem = regions.find(item => item._id === region);
            if (currentItem && currentItem.name) {
                return currentItem.name?.am
            } else {
                return region || "";
            }
        } else {
            return region || "";
        }
    }

    const getCity = (city, cities) => {
        if (cities && cities.length && city) {
            let currentItem = cities.find(item => item._id === city);
            if (currentItem && currentItem.name) {
                return currentItem.name?.am
            } else {
                return city || "";
            }
        } else {
            return city || "";
        }
    }

    return order ?
        <Modal size="lg" show={showModal} onHide={cancel} scrollable>
            <Modal.Header closeButton>
                <Modal.Title><b>Order Info</b></Modal.Title>
            </Modal.Header>
            <Modal.Body id="document">
                <Container>
                    <Row>
                        <Col md={12} className='text-center'>
                            <span>
                                Order owner
                            </span>
                            {
                                order.user ?
                                    <h4 className='m-0 d-flex justify-content-center rounded-3'>
                                        {order.user.firstName} {order.user.lastName}
                                    </h4>
                                    :
                                    <h4 className='m-0 d-flex justify-content-center rounded-3'>
                                        Unknown client
                                    </h4>
                            }
                            <hr className='my-3' />
                        </Col>
                        <Col sm={6}>
                            <span>
                                Order number
                            </span>
                            <h4 className='m-0 d-flex justify-content-center p-3 bg-dark text-white rounded-3'>
                                #{order.orderId}
                            </h4>
                        </Col>
                        <Col sm={6} className='mt-sm-0 mt-3'>
                            <span>
                                Total amount
                            </span>
                            <h4 className='m-0 d-flex justify-content-center p-3 bg-success text-white rounded-3'>
                                {formatCurrency(order.totalAmount, 2)} ֏
                            </h4>
                        </Col>
                        <Col sm={12}>
                            <hr />
                        </Col>
                        <Col sm={12} md={6}>
                            {
                                order?.deliveryPrice ?
                                    <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                        <b> Sheeping: </b>{formatCurrency(order?.deliveryPrice, 2)} ֏
                                    </div>
                                    : null
                            }
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> Province:</b>
                                <span>
                                    {getRegion(order.address?.province, regions)}
                                </span>
                            </div>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> City:</b>
                                <span>
                                    {getCity(order.address?.city, cities)}
                                </span>
                            </div>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> Address:</b> {order.address?.address}
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> Payment status: </b> {order.payment.status}
                            </div>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> Paymnt method:</b> {getPaymentType(order.payment?.paymentType)}
                            </div>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b> Phone number:</b> (+374) {order.address?.phoneNumber}
                            </div>
                            <div className='border rounded p-3 mt-1 d-flex justify-content-between'>
                                <b>Date:</b> {moment(new Date(order.createdAt)).format("LL HH:mm")}
                            </div>
                        </Col>
                        <Col sm={12}>
                            <hr />
                        </Col>
                        <Col sm={12}>
                            {
                                order.comment ?
                                    <div className='border rounded p-3 mt-1'>
                                        <b>Comment:{" "}</b> {order.comment}
                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                    {
                        order.products && order.products.length ?
                            <Row className='mt-2'>
                                <Table bordered responsive striped className="text-center">
                                    <thead>
                                        <tr>
                                            <th className="align-middle text-nowrap">#</th>
                                            <th className="align-middle text-nowrap">Image</th>
                                            <th className="align-middle text-nowrap">Name</th>
                                            <th className="align-middle text-nowrap">Price</th>
                                            <th className="align-middle text-nowrap">Count</th>
                                            <th className="align-middle text-nowrap">From set</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.products.map(({ product, price, count, fromSet }, index) => {
                                            if (product) {
                                                return (
                                                    <tr key={product._id}>
                                                        <td className="align-middle"><b>{index + 1}</b></td>
                                                        <td className="align-middle">
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${serverImgURL(product.image)})`,
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundSize: "contain",
                                                                    height: "3rem",
                                                                    backgroundPosition: "center"
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="align-middle"><b>{product._title?.am || ""}</b></td>
                                                        <td className="align-middle"><b>{price}{"֏"}</b></td>
                                                        <td className="align-middle"><b>{count}</b></td>
                                                        <td className="align-middle"><b>{fromSet ? "Yes" : "No"}</b></td>
                                                    </tr>
                                                )
                                            }
                                        }
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                            : null
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancel}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal> : null
}

export default OrderView;