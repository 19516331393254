import React, { useState, useEffect, useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Card, CardBody, CardTitle, CardSubtitle, Input, Form, Col, Label, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { withTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import MainService from "./../../../services/mainService"
import { ERROR_KEY, MAXIMUM_UPLOAD_FILE_SIZE, NUMBER_KEY, VALID_IMAGE_TYPES_KEY, fieldValidation } from '../../../constants/mainKeys'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AlertService from '../../../services/alertService'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { ProgressBar } from 'react-bootstrap'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import { serverImgURL } from '../../../helpers'

const buttonSpinnerId = uuid();
const CityForm = (props) => {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const { history } = props;

  const [values, setValues] = useState({
    parent: "",
    name: {
      am: "",
      en: "",
      ru: ""
    },
    parent: "",
    shippingPrice: "",
    freeShippingPrice: "",
    isActive: true,
  });

  const { locationId } = useParams();
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [productData, setProductData] = useState(null);
  const [changes, setChanges] = useState(false);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (locationId) getShippingLocationById();
    getRegions();
    return () => {
      setProductData(null);
    }
  }, [])

  const getRegions = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getRegions().then(response => {
      if (response && response.data && response.data) {
        const data = [...response.data];
        setRegions(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getShippingLocationById = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getShippingLocationById(locationId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setProductData(data);
        setValues(data)
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onChangeTitle = (event, lang, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevValues) => ({
      ...prevValues,
      name: {
        ...prevValues.name,
        [lang]: event.target.value,
      },
    }));
    setIsInvalidSubmit(false);
  };

  const onNumberChange = (event, fieldName, maxValue = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value === '') {
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
      return false;
    }
    if ((typeof +event.target.value === NUMBER_KEY && +event.target.value >= 0 && +event.target.value <= maxValue)) {
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: +event.target.value,
      }));
    }
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: item.value,
    }));
    setChanges(true);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.name.am.trim().length || !values.name.en.trim().length || !values.name.ru.trim().length || !values.parent) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    let _values = { ...values };
    if (!_values.shippingPrice) {
      _values.shippingPrice = 0;
    }
    if (!_values.freeShippingPrice) {
      _values.freeShippingPrice = 0;
    }
    (locationId ? ApiService.updateShippingLocation(locationId, _values) : ApiService.createShippingLocation(_values)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert("success", "Data saved");
      history.push("/cities")
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>City Form</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="City Form"
        />
        <hr className='mt-0' />
        <Row>
          <Form
            onChange={() => setChanges(true)}
            onSubmit={onSubmit}
          >
            <Row>
              <Col sm={12} className='mb-3'>
                <div className='border rounded p-2'>
                  <Row>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label htmlFor='nameAm'>Name (am)*</Label>
                        <Input
                          id='nameAm'
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values.name.am.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values.name.am}
                          onChange={(event) => onChangeTitle(event, "am", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label htmlFor='nameEn'>Name (en)*</Label>
                        <Input
                          id='nameEn'
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values.name.en.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values.name.en}
                          onChange={(event) => onChangeTitle(event, "en", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label htmlFor='nameRu'>Name (ru)*</Label>
                        <Input
                          id='nameRu'
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values.name.ru.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values.name.ru}
                          onChange={(event) => onChangeTitle(event, "ru", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={12} className='mb-3'>
                <div className='border rounded p-2'>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Choose the parent region*</Label>
                        {
                          regions && regions.length ?
                            <ReactSelectOption
                              value={values.parent}
                              selectedValue={(() => {
                                const selectedValue = { ...regions.find(data => data._id === values.parent) };
                                if (Object.keys(selectedValue).length) {
                                  selectedValue.label = selectedValue.name ? selectedValue.name.am : "";
                                  selectedValue.value = selectedValue.value;
                                }
                                return selectedValue;
                              })()}
                              className={`rounded ${isInvalidSubmit && !values.parent ? "error-border" : ""}`}
                              items={regions.filter(item => item._id !== locationId).map(data => ({ label: data.name ? data.name.am : "", value: data._id }))}
                              onChange={item => onSelectOptionChange(item, "parent")}
                            />
                            : null
                        }
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor='shippingPrice'>Shipping Price</Label>
                        <Input
                          id="shippingPrice"
                          type="text"
                          className={`form-control`}
                          value={values.shippingPrice}
                          onChange={(event) => onNumberChange(event, "shippingPrice")}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor='shippingPrice'>Free Shipping Price</Label>
                        <Input
                          id="shippingPrice"
                          type="text"
                          className={`form-control`}
                          value={values.freeShippingPrice}
                          onChange={(event) => onNumberChange(event, "freeShippingPrice")}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className='d-block'>Is Active</Label>
                        <input
                          id="isActive"
                          type="checkbox"
                          className="form-check-input"
                          checked={values.isActive}
                          onChange={(event) => {
                            setValues(() => ({
                              ...values,
                              isActive: event.target.checked
                            }))
                          }}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor="isActive"
                        >
                          Yes
                        </label>
                      </div>
                    </Col>

                  </Row>
                </div>
              </Col>
              <Col md={12}>
                <hr />
                <div className='d-flex justify-content-end'>
                  <ActionButton
                    type="submit"
                    name="Save"
                    color="primary"
                    disabled={!changes}
                    spinnerClass={`w_50`}
                    className="btn btn-primary btn-block px-4"
                    spinnerId={buttonSpinnerId}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(CityForm)