import React, { useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Table, Card, CardBody, Button, Col } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { withTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useCallback } from 'react'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import AlertService from '../../../services/alertService'
import uuid from 'react-uuid'
import ApiService from '../../../services/apiService'
import NoData from '../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import { serverImgURL } from '../../../helpers'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const buttonSpinnerId = uuid();
const Products = (props) => {

  const dispatch = useDispatch();
  const { history } = props;
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [dragProductId, setDragProductId] = useState(null);
  const [pageSize, setPageSize] = useState(10)
  const [values, setValues] = useState({
    page: 0,
    limit: pageSize,
    sortBy: "",
    orderBy: "",
    category: "",
    brand: "",
    search: "",
    displayOrder: true
  });

  const pageSizes = [10, 20, 50, 100, 500];

  useEffect(() => {
    getBrands();
    getCategoriesList();

    return () => {
      setBrands([]);
      setCategories([]);
    }

  }, [])

  useEffect(() => {
    getProducts();
    return () => {
      setProducts([]);
    }
  }, [values])

  const handlePageClick = (event) => {
    setValues((items) => ({
      ...items,
      page: event.selected
    }))
  };

  const getBrands = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getBrands().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setBrands(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCategoriesList = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCategoriesList().then(response => {
      if (response && response.data && response.data.data) {
        const data = [...response.data.data];
        setCategories(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getProducts = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getProducts(values).then(response => {
      if (response && response.data && response.data.products) {
        const data = [...response.data.products];
        setProducts(data);
      }
      if (response && response.data && response.data.count) {
        setProductsCount(response.data.count)
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteProduct = (productId, spinnerId) => {
    if (!productId) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      ApiService.deleteProduct(productId).then(response => {
        setProducts(products.filter(item => item._id !== productId));
        AlertService.alert("success", "Product deleted successfully");
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const onSelectOptionChange = (item) => {
    if (!item || (item && item.value === pageSize)) { return false; }
    setValues((items) => ({
      ...items,
      page: 0,
      limit: item.value
    }))
    setPageSize(item.value)
  }

  const drop = (dropProductId) => {
    if (dropProductId && dragProductId && dropProductId !== dragProductId) {
      const spinnerId = uuid();
      setPageSpinner(spinnerId)
      ApiService.changeProcuctDisplayOrder(dropProductId, dragProductId).then(response => {
        extractPageSpinner(spinnerId);
        setDragProductId(null);
        AlertService.alert("success", "Data saved");
        getProducts(values)
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const drag = (productId) => {
    productId && setDragProductId(productId)
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Products</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Products"
        />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/product-form`)
              }}
            >
              Create a product
            </Button>
          </Col>
        </Row>
        <Row>
          {
            products && products.length ?
              <Col sm={12}>
                <Table bordered responsive className="text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="align-middle">Image</th>
                      <th className="align-middle">Title</th>
                      <th className="align-middle">Category</th>
                      <th className="align-middle">Price</th>
                      <th className="align-middle">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((item, index) => (
                      <tr
                        key={`${item._id}`}
                        style={{ cursor: "move" }}
                        draggable={true}
                        onDrop={() => drop(item._id)}
                        onDragOver={event => event.preventDefault()}
                        onDragStart={() => drag(item._id)}
                      >
                        <td className="align-middle">
                          <b> {index + 1}</b>
                        </td>
                        <td className="align-middle">
                          <img
                            src={serverImgURL(item.image)}
                            style={{ maxHeight: 80 }}
                            alt="/"
                          />
                        </td>
                        <td className="align-middle"><b>{item._title?.am || ""}</b></td>
                        <td className="align-middle">
                          {
                            categories && categories.length ?
                              categories.map(category => {
                                if (category._id === item.category) {
                                  return category && category._name ? category._name.am : ""
                                }
                              })
                              : null
                          }
                        </td>
                        <td className="align-middle">
                          {item.newPrice ? (
                            <span className="fw-500">
                              {item.newPrice}֏
                              <br />
                              <span
                                style={{
                                  textDecorationLine: "line-through",
                                  fontWeight: "300",
                                }}
                              >
                                {item.price}֏
                              </span>
                            </span>
                          ) : (
                            <span className="fw-500">{item.price}֏</span>
                          )}
                        </td>

                        <td className="align-middle">
                          <div
                            className="d-flex justify-content-center gap-1"
                          >
                            <Button
                              color="warning"
                              onClick={() => history.push(`/product-form/${item._id}`)}
                            >
                              Edit
                            </Button>
                            <ActionButton
                              type="button"
                              name="Delete"
                              color="danger"
                              spinnerClass={`w_50`}
                              spinnerId={`${buttonSpinnerId}_${index}`}
                              onClick={() => deleteProduct(item._id, `${buttonSpinnerId}_${index}`)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className=' mt-4 d-flex justify-content-end align-items-start w-100'>

                  <div style={{ marginTop: "2px", marginRight: "5px", width: "100px" }}>
                    <ReactSelectOption
                      value={pageSize}
                      menuPlacement="top"
                      selectedValue={(() => {
                        const selectedValue = [...pageSizes].find(data => data === pageSize);
                        if (selectedValue) {
                          return { label: selectedValue, value: selectedValue };
                        } else {
                          return ""
                        }
                      })()}
                      className={`rounded`}
                      items={pageSizes.map(data => ({ label: data, value: data }))}
                      onChange={item => onSelectOptionChange(item)}
                    />
                  </div>

                  {
                    productsCount && productsCount > pageSize ?
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={Math.round(productsCount / pageSize)}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={values.page}
                      />
                      : null
                  }


                </div>
              </Col>
              : <NoData />
          }
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(Products)