import React, { useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Table, Card, CardBody, Button, Col } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useCallback } from 'react'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import AlertService from '../../../services/alertService'
import uuid from 'react-uuid'
import ApiService from '../../../services/apiService'
import NoData from '../../../components/NoData/NoData'
import ActionButton from '../../../components/Buttons/ActionButton'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'

const buttonSpinnerId = uuid();
const Cities = (props) => {

  const { history } = props;
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    getCities();
    getRegions();
  }, [])

  const getRegions = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getRegions().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setRegions(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCities = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCities().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCities(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteShippingLocation = (cityId, spinnerId) => {
    if (!cityId) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      ApiService.deleteShippingLocation(cityId).then(response => {
        AlertService.alert("success", "The city deleted successfully");
        setCities(cities.filter(item => item._id !== cityId));
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Cities</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Cities"
        />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/city-form`)
              }}
            >
              Create a City
            </Button>
          </Col>
        </Row>
        <Row>
          {
            cities && cities.length ?
              <Table bordered responsive className="text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>City</th>
                    <th>Region</th>
                    <th>Shipping Price</th>
                    <th>Free Shipping Price</th>
                    <th className="align-middle">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cities.map((item, index) => (
                    <Auxiliary>
                      <tr
                        key={item._id}
                      >
                        <td className="align-middle"><b>{index + 1}</b></td>
                        <td className="align-middle">{item.name?.am || ""}</td>
                        {
                          regions && regions.length ?
                            regions.map(region => {
                              if (region._id === item.parent) {
                                return <td className="align-middle">{region.name?.am}</td>
                              }
                            })

                            : <td className="align-middle"></td>
                        }
                        <td className="align-middle">{item.shippingPrice}֏</td>
                        <td className="align-middle">{item.freeShippingPrice}֏</td>

                        <td className="align-middle">
                          <div
                            className="d-flex justify-content-center gap-1"
                          >
                            <Button
                              color="warning"
                              onClick={() => history.push(`/city-form/${item._id}`)}
                            >
                              Edit
                            </Button>
                            <ActionButton
                              type="button"
                              name="Delete"
                              color="danger"
                              spinnerClass={`w_50`}
                              spinnerId={`${buttonSpinnerId}_${index}`}
                              onClick={() => deleteShippingLocation(item._id, `${buttonSpinnerId}_${index}`)}
                            />
                          </div>
                        </td>
                      </tr>
                    </Auxiliary>
                  ))}
                </tbody>
              </Table>
              : <NoData />
          }
        </Row>
      </Container>
    </div>
  )
}



export default Cities