import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import Dashboard from '../../pages/authPages/dashboard/Dashboard'
import Logout from '../../pages/unauthPages/Logout'
import Users from '../../pages/authPages/users/Users'
import Products from '../../pages/authPages/products/Products'
import ProductForm from '../../pages/authPages/products/ProductForm'
import Categories from '../../pages/authPages/categories/Categories'
import CategoryForm from '../../pages/authPages/categories/CategoryForm'
import Brands from '../../pages/authPages/brands/Brands'
import ContactUs from '../../pages/authPages/contactUs/ContactUs'
import AboutUs from '../../pages/authPages/aboutUs/AboutUs'
import Chat from '../../pages/authPages/chat/Chat'
import Orders from '../../pages/authPages/orders/Orders'
import Configs from '../../pages/authPages/configs/Configs'
import Library from '../../pages/authPages/library/Library'
import PaymentSystems from '../../pages/authPages/paymentSystems/PayemntSystems'
import Regions from '../../pages/authPages/regions/Regions'
import RegionForm from '../../pages/authPages/regions/RegionForm'
import Cities from '../../pages/authPages/cities/Cities'
import CityForm from '../../pages/authPages/cities/CityForm'

export default function AuthMiddleware() {

  return (
    <Switch>
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/products" exact component={Products} />
      <Route path="/product-form" exact component={ProductForm} />
      <Route path="/product-form/:productId" exact component={ProductForm} />
      <Route path="/users" exact component={Users} />
      <Route path="/regions" exact component={Regions} />
      <Route path="/region-form" exact component={RegionForm} />
      <Route path="/region-form/:locationId" exact component={RegionForm} />
      <Route path="/cities" exact component={Cities} />
      <Route path="/city-form" exact component={CityForm} />
      <Route path="/city-form/:locationId" exact component={CityForm} />
      <Route path="/categories" exact component={Categories} />
      <Route path="/category-form" exact component={CategoryForm} />
      <Route path="/category-form/:categoryId" exact component={CategoryForm} />
      <Route path="/brands" exact component={Brands} />
      <Route path="/contact-us" exact component={ContactUs} />
      <Route path="/about-us" exact component={AboutUs} />
      <Route path="/chat" exact component={Chat} />
      <Route path="/chat/:clientId" exact component={Chat} />
      <Route path="/orders" exact component={Orders} />
      <Route path="/configs" exact component={Configs} />
      <Route path="/library" exact component={Library} />
      <Route path="/paymentSystems" exact component={PaymentSystems} />

      <Route path="/logout" exact component={Logout} />
      <Redirect to={`/dashboard`} />
    </Switch>
  )
}
