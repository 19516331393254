import React, { useCallback, useEffect, useState, useRef } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactPaginate from 'react-paginate'
import { Table, Modal } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY, NUMBER_KEY, PAGE_NUMBER } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Card, CardBody, Label, Container, Input } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import moment from 'moment'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { MetaTags } from 'react-meta-tags'
import { MdDownload } from "react-icons/md"
import jsPDF from "jspdf";
import OrderInvoiceTemplate from '../../../templates/orderInvoice'
import { serverImgURL } from '../../../helpers'
import { useDebounce } from 'use-debounce';
import { setDateFrom, setDateTo, setOrderSearch, setOrders, setOrdersCount, setPendingOrderIds } from '../../../store/order/actions'
import { IoPrint } from "react-icons/io5"
import { FaEye } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";
import { RiEdit2Fill } from "react-icons/ri";
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import OrderView from './OrderView'
import OrderRefund from './OrderRefund'



const buttonSpinnerId = uuid();
export default function Orders(props) {

  const reportTemplateRef = useRef(null);
  const dispatch = useDispatch();
  // const [ordersCount, setOrdersCount] = useState(0);
  // const [orders, setOrders] = useState([]);
  const { orders, ordersCount, orderSearch, dateFrom, dateTo } = useSelector(state => state.Order);
  const [status, setStatus] = useState("");
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [invoiceBtnDisabled, setInvoiceBtnDisabled] = useState(false);
  const [order, setOrder] = useState(null);
  const [_order, _setOrder] = useState(null);
  const [isShowOrderStatusUpdateModal, setIsShowOrderStatusUpdateModal] = useState(false);
  const [orderUpdatedStatus, setOrderUpdatedStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isShowRefundModal, setIsShowRefundModal] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [refundCheckbox, setRefundCheckbox] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 50, 100, 500];
  const [debounceSearch] = useDebounce(orderSearch, 1000);
  const [debounceDateFrom] = useDebounce(dateFrom, 1000);
  const [debounceDateTo] = useDebounce(dateTo, 1000);

  const [total, setTotal] = useState("");
  const [cash, setCash] = useState("");
  const [uncashe, setUncashe] = useState("");
  const [count, setCount] = useState("");

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  const clearFilter = () => {
    setStatus("");
    dispatch(setOrderSearch(""))
    dispatch(setDateFrom(""))
    dispatch(setDateTo(""))
  }

  const orderUpdatedStatusses = [
    {
      label: "Confirmed",
      value: "confirmed",
    },
    {
      label: "In Delivery",
      value: "inDelivery",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Canceled",
      value: "canceled",
    },
  ]

  const statuses = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Confirmed",
      value: "confirmed",
    },
    {
      label: "In Delivery",
      value: "inDelivery",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Canceled",
      value: "canceled",
    },
  ]

  useEffect(() => {
    getRegions();
    getCities();
    return () => {
      dispatch(setOrderSearch(""));
      dispatch(setDateFrom(""));
      dispatch(setDateTo(""));
    }
  }, [])

  useEffect(() => {
    getOrders(activePageNumber);
  }, [status, pageSize, debounceSearch, debounceDateFrom, debounceDateTo]);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getOrders(event.selected)
  }

  const getOrders = (pageNumber) => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getOrders(pageNumber, pageSize, status, debounceSearch, debounceDateFrom, debounceDateTo).then(response => {
      if (response && response.data) {
        if (response.data.orders) dispatch(setOrders(response.data.orders));
        if (response.data.count) dispatch(setOrdersCount(response.data.count));

        setTotal(response.data.total || 0);
        setCash(response.data.totalCash || 0);
        setUncashe(response.data.totalUncash || 0);
        setCount(response.data.count || 0);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const getRegions = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getRegions().then(response => {
      if (response && response.data) {
        setRegions(response.data)
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const getCities = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCities().then(response => {
      if (response && response.data) {
        setCities(response.data)
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onSelectOptionChange = (item, cb) => {
    if (!item) { return false; }
    cb(item.value);
  }

  const _onSelectOptionChange = (item) => {
    if (!item || (item && item.value === pageSize)) { return false; }
    setPageSize(item.value)
  }

  const formatCurrency = (number, decimalPlaces = 0) => {
    return Number(number).toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }

  const getStatusColor = (status) => {
    let color = '';
    switch (status) {
      case "confirmed":
        color = 'primary';
        break;
      case "inDelivery":
        color = 'info';
        break;
      case "refunded":
        color = 'info';
        break;
      case "completed":
        color = 'success';
        break;
      case "canceled":
        color = 'danger';
        break;
      case "pending":
        color = 'warning';
        break;
      case "new":
        color = 'success';
        break;
      default:
        color = 'dark';
        break;
    }
    return color;
  };

  const getPaymentStatusColor = (status) => {
    let color = '';
    switch (status) {
      case "paid":
        color = 'success';
        break;
      case "pending":
        color = 'warning';
        break;
      case "fail":
        color = 'danger';
        break;
      case "canceled":
        color = 'danger';
        break;
      case "unpaid":
        color = 'danger';
        break;
      case "refunded":
        color = 'info';
        break;
      default:
        color = 'dark';
        break;
    }
    return color;
  };

  const onGetInvoice = (id) => {
    if (!id) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to download order invoice.",
      "Yes",
      "No"
    ).then(() => {
      if (reportTemplateRef && reportTemplateRef.current) {
        setInvoiceBtnDisabled(true);
        try {
          const doc = new jsPDF({
            format: "a4",
            orientation: "landscape",
            unit: "px",
          });
          doc.setFont("Inter-Regular", "normal");
          doc.html(reportTemplateRef.current, {
            async callback(doc) {
              doc.save(`${id}-invoice`);
              setInvoiceBtnDisabled(false);
              setOrder(null);
            },
          });
        } catch (error) {
          AlertService.alert("error", "Uncaught error");
          setInvoiceBtnDisabled(false);
          setOrder(null);
        }
      }
    })
  };

  const print = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to print the current order.",
      "Yes",
      "No"
    ).then(() => {
      const content = document.getElementById("divcontents");
      if (!content) {
        console.error("Element with ID 'divcontents' not found.");
        return;
      }
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";
      document.body.appendChild(iframe);
      const pri = iframe.contentWindow;
      const cleanUp = () => {
        document.body.removeChild(iframe);
        window.removeEventListener('focus', cleanUp);
        _setOrder(null);
      };
      window.addEventListener('focus', cleanUp);
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    });
  };

  const onUpdateOrderStatus = () => {
    if (!order || !orderUpdatedStatus) { return false; }
    setButtonSpinner(buttonSpinnerId);
    ApiService.updateOrderStatus(order._id, orderUpdatedStatus).then(response => {
      if (response && response.data) {
        const updatedArray = orders.map(item =>
          item._id === order._id ? { ...item, ...response.data } : item
        );
        let _pendingOrderIds = [];
        updatedArray.forEach(item => {
          if (item.status === "pending") {
            _pendingOrderIds.push(item._id)
          }
        })
        dispatch(setPendingOrderIds(_pendingOrderIds))
        dispatch(setOrders(updatedArray));
      }
      AlertService.alert("success", "Data saved")
      extractButtonSpinner(buttonSpinnerId);
      cancel();
    }).catch(error => getFail(error, buttonSpinnerId))
  };

  const cancelOrder = (order) => {
    if (!order) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(buttonSpinnerId);
      ApiService.updateOrderStatus(order._id, "canceled").then(() => {
        extractButtonSpinner(buttonSpinnerId);
        AlertService.alert("success", "Data saved");
        getOrders(activePageNumber)
      }).catch(error => getFail(error, buttonSpinnerId))
    })

  };

  const onNumberChange = (event, cb, maxValue = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && +event.target.value >= 0 && +event.target.value <= maxValue)) {
      cb(+event.target.value);
    }
  }

  const getPaymentType = (paymentType) => {
    switch (paymentType) {
      case "cash":
        return "Cash"
      case "bank_card":
        return "Bank Card"
      case "idram":
        return "IDram"
      default:
        break;
    }
  }

  const refund = () => {
    if (!order && !refundAmount) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(buttonSpinnerId);
      ApiService.refund(order.orderId, order.payment?.paymentId, refundAmount).then(() => {
        extractButtonSpinner(buttonSpinnerId);
        cancel();
        AlertService.alert("success", "Data saved");
        getOrders(activePageNumber)
      }).catch(error => getFail(error, buttonSpinnerId))
    })
  }

  const getToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    cancel()
  }

  const cancel = () => {
    setOrder(null);
    setIsShowOrderStatusUpdateModal(false);
    setOrderUpdatedStatus("");
    setShowModal(false);
    setIsShowRefundModal(false);
    setRefundAmount("");
    setRefundCheckbox(false);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Orders</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Orders"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row>
          <Col md={3} className='mb-3'>
            <Label>Filter by status</Label>
            <ReactSelectOption
              value={status}
              selectedValue={(() => {
                const selectedValue = {
                  ...statuses.find(data => data.value === status)
                };
                if (Object.keys(selectedValue).length) {
                  selectedValue.label = selectedValue.label;
                  selectedValue.value = selectedValue.value;
                }
                return selectedValue;
              })()}
              className={`rounded`}
              items={statuses.map(data => ({ label: data.label, value: data.value }))}
              onChange={item => onSelectOptionChange(item, setStatus)}
            />
          </Col>
          <Col md={3} className='mb-3'>
            <Label>Search by order number</Label>
            <Input
              type="number"
              placeholder='Ex. 3317191'
              className={`form-control`}
              value={orderSearch}
              onChange={(event) => dispatch(setOrderSearch(event.target.value))}
            />
          </Col>

          <Col md={3} className='mb-3'>
            <Label htmlFor='from'>Date From {dateFrom ? <span className='ms-1' style={{ fontWeight: "600" }}>{moment(new Date(dateFrom)).format("ll")}</span> : null}</Label>
            <Input
              id="from"
              type="date"
              max={getToday()}
              className={`form-control`}
              value={dateFrom}
              onChange={(event) => dispatch(setDateFrom(event.target.value))}
            />
          </Col>
          <Col md={3} className='mb-3'>
            <Label htmlFor='to'>Date To {dateTo ? <span className='ms-1' style={{ fontWeight: "600" }}>{moment(new Date(dateTo)).format("ll")}</span> : null}</Label>
            <Input
              id="to"
              type="date"
              min={dateFrom}
              className={`form-control`}
              value={dateTo}
              onChange={(event) => dispatch(setDateTo(event.target.value))}
            />
          </Col>
          {
            status || orderSearch || dateFrom || dateTo ?
              <Col sm={12}>
                <div className='d-flex justify-content-end'>
                  <Button
                    color="primary"
                    title='View'
                    onClick={clearFilter}
                  >
                    Clear Filter
                  </Button>
                </div>
              </Col>
              : null
          }
          <Col sm={12} className='mb-2'>
            <div className='d-flex flex-wrap gap-3 my-2 border p-2 rounded border-success'>
              <p className='mb-0'>
                Count: <b>{count}</b>
              </p>

              <p className='mb-0'>
                Total: <b>{formatCurrency(total, 2)} ֏</b>
              </p>

              <p className='mb-0'>
                Cash: <b>{formatCurrency(cash, 2)} ֏</b>
              </p>

              <p className='mb-0'>
                Non-cash: <b>{formatCurrency(uncashe, 2)} ֏</b>
              </p>
            </div>
          </Col>
          {
            orders && orders.length ?
              <Col sm={12}>
                <Table bordered responsive className="text-center">
                  <thead>
                    <tr>
                      <th className="align-middle text-nowrap">#</th>
                      <th className="align-middle text-nowrap">Order number</th>
                      <th className="align-middle text-nowrap">Created Date</th>
                      <th className="align-middle text-nowrap">User</th>
                      <th className="align-middle text-nowrap">Total amount</th>
                      <th className="align-middle text-nowrap">Status</th>
                      <th className="align-middle text-nowrap">Payment method</th>
                      <th className="align-middle text-nowrap">Payment status</th>
                      <th className="align-middle text-nowrap">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((item, index) => (
                      <tr key={`${item._id}`}>
                        <td className="align-middle"><b>{index + 1}</b></td>
                        <td className="align-middle"><b>{item.orderId}</b></td>
                        <td className="align-middle"><b>{moment(new Date(item.createdAt)).format("ll HH:mm")}</b></td>
                        <td className="align-middle">
                          {item.user ?
                            <span>{item.user.firstName} {item.user.lastName}</span>
                            : <span>Unknown client</span>}
                        </td>
                        <td className="align-middle"><b>{formatCurrency(item.totalAmount, 2)} ֏</b></td>
                        <td className={`align-middle`}>
                          <b style={{ pointerEvents: "none" }} className={`text-uppercase w-100 btn btn-outline-${getStatusColor(item.status)} ${item.status === "pending" ? "impulse_td" : ""}`}>{item.status}</b>
                        </td>
                        <td className={`align-middle`}>
                          <b className='text-capitalize w-100'>{item?.payment?.paymentType && item?.payment?.paymentType === "bank_card" ? "Ameria Bank" : item?.payment?.paymentType}</b>
                        </td>
                        <td className="align-middle">
                          <b style={{ pointerEvents: "none" }} className={`text-uppercase btn w-100 btn-outline-${getPaymentStatusColor(item?.payment?.status)}`}>{item?.payment?.status}</b>
                        </td>
                        <td className="align-middle ">
                          <div
                            className="d-flex justify-content-start gap-1"
                          >
                            <Button
                              color="success"
                              title='View'
                              onClick={() => {
                                setOrder(item);
                                setShowModal(true);
                              }}
                            >
                              <FaEye size={20} />
                            </Button>
                            <Button
                              color="info"
                              title='Print'
                              className='text-nowrap'
                              onClick={() => {
                                _setOrder(item);
                                print()
                              }}
                            >
                              <IoPrint size={20} />
                            </Button>

                            {
                              item.payment?.paymentType !== "cash" && item?.payment?.status.toLocaleLowerCase() === "pending" ?
                                <Button
                                  color="danger"
                                  className='text-nowrap w-100'
                                  onClick={() => {
                                    cancelOrder(item)
                                  }}
                                >
                                  Cancel
                                </Button> :
                                <Button
                                  color="warning"
                                  title='Update status'
                                  className='text-nowrap w-100'
                                  disabled={item.status === "canceled" || (item.payment?.paymentType !== "cash" && item?.payment?.status.toLocaleLowerCase() === "pending")}
                                  onClick={() => {
                                    setOrderUpdatedStatus(item.status)
                                    setOrder(item);
                                    setIsShowOrderStatusUpdateModal(true);
                                  }}
                                >
                                  <RiEdit2Fill size={20} />
                                </Button>
                            }
                            <Button
                              color="danger"
                              title='Refund'
                              className='text-nowrap'
                              disabled={item.payment?.paymentType === "bank_card" && item.status !== "canceled" && item?.payment?.status.toLocaleLowerCase() !== "pending" && item?.payment?.status.toLocaleLowerCase() !== "canceled" ? false : true}
                              onClick={() => {
                                setOrder(item);
                                setIsShowRefundModal(true);
                              }}
                            >
                              <RiRefund2Line size={20} />
                            </Button>
                            {/* <Button
                                color="info"
                                className='text-nowrap'
                                onClick={() => {
                                  onGetInvoice(item._id);
                                  setOrder(item);
                                }}
                              >
                                Invoice
                                <b><MdDownload size={18} className='ms-1' /></b>
                              </Button> */}

                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className=' mt-4 d-flex justify-content-end align-items-start w-100'>
                  <div style={{ marginTop: "2px", marginRight: "5px", width: "100px" }}>
                    <ReactSelectOption
                      value={pageSize}
                      menuPlacement="top"
                      selectedValue={(() => {
                        const selectedValue = [...pageSizes].find(data => data === pageSize);
                        if (selectedValue) {
                          return { label: selectedValue, value: selectedValue };
                        } else {
                          return ""
                        }
                      })()}
                      className={`rounded`}
                      items={pageSizes.map(data => ({ label: data, value: data }))}
                      onChange={item => _onSelectOptionChange(item)}
                    />
                  </div>
                  {
                    ordersCount && ordersCount > pageSize ?
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={Math.round(ordersCount / pageSize)}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={activePageNumber}
                      />
                      : null
                  }
                </div>
              </Col>
              : <NoData />
          }
        </Row>
      </div>
      {
        isShowOrderStatusUpdateModal && order ?
          <Modal
            show={true}
            onHide={() => {
              cancel()
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Change order status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactSelectOption
                value={orderUpdatedStatus}
                selectedValue={(() => {
                  const selectedValue = { ...orderUpdatedStatusses.find(data => data.value.toLocaleLowerCase() === orderUpdatedStatus.toLocaleLowerCase()) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.label;
                    selectedValue.value = selectedValue.value;
                  }
                  return selectedValue;
                })()}
                className={`rounded`}
                items={orderUpdatedStatusses.map(data => ({ label: data.label, value: data.value }))}
                onChange={item => onSelectOptionChange(item, setOrderUpdatedStatus)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="secondary"
                className='px-4'
                onClick={() => {
                  cancel()
                }}
              >
                Cancel
              </Button>
              <ActionButton
                type="button"
                name="Save"
                color="primary"
                disabled={!orderUpdatedStatus || (orderUpdatedStatus && orderUpdatedStatus === order?.status)}
                spinnerClass={`w_50`}
                className="btn btn-primary btn-block px-4"
                spinnerId={buttonSpinnerId}
                onClick={onUpdateOrderStatus}
              />
            </Modal.Footer>
          </Modal>
          : null
      }
      <OrderView
        order={order}
        showModal={showModal}
        cancel={cancel}
        regions={regions}
        cities={cities}
      />
      <OrderRefund
        order={order}
        isShowRefundModal={isShowRefundModal}
        setRefundAmount={setRefundAmount}
        onNumberChange={onNumberChange}
        refundAmount={refundAmount}
        buttonSpinnerId={buttonSpinnerId}
        refund={refund}
        cancel={cancel}
        refundCheckbox={refundCheckbox}
        setRefundCheckbox={setRefundCheckbox}
      />

      <div
        ref={reportTemplateRef}
        className={`${invoiceBtnDisabled ? "d-block" : "d-none"}`}
        style={{
          width: "627px",
          margin: "2px",
          padding: 0,
        }}
      >
        {order ? <OrderInvoiceTemplate {...order} regions={regions} cities={cities} /> : null}
      </div>

      <div id="ifmcontentstoprint" />
      <div id="divcontents" style={{ opacity: "0.1" }}>
        {_order ? <OrderInvoiceTemplate {..._order} regions={regions} cities={cities} /> : null}
      </div>
    </div >
  )
}
