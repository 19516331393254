import moment from "moment";
import { API_URL_KEY } from "./constants/apiKeys";

export const serverAvatarURL = (fileName) => {
  return API_URL_KEY + "/uploads/avatar/" + (fileName || "avatar.png");
};

export const serverImgURL = (fileName) => {
  return API_URL_KEY + "/uploads/" + (fileName || "default.webp");
};

export const serverFileURL = (fileName) => {
  return API_URL_KEY + "/files/" + fileName;
};

export const getCorrectTime = (time) => {
  const date = new Date();
  const [h, m] = time.split(":");
  date.setUTCHours(+h);
  date.setUTCMinutes(+m);

  const newTime = moment(date).format("HH:mm");
  return newTime;
};

export const getLondonTime = (time) => {
  const date = new Date();
  const [h, m] = time.split(":");
  date.setHours(+h);
  date.setMinutes(+m);
  const newTime = moment(date).format("HH:mm");
  return newTime;
};

export function numberWS(x) {
  return x ? x.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1.") : "0";
}
