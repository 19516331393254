import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ActionButton from '../../../components/Buttons/ActionButton'
import { MetaTags } from 'react-meta-tags'

const buttonSpinnerId = uuid();
export default function AboutUs(props) {

  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [isShowBrandForm, setIsShowBrandForm] = useState(false);
  const [values, setValues] = useState({
    title: "",
    description: "",
  })

  useEffect(() => {
    setAboutUsPageData();
    return () => {
      setData([])
    }
  }, [])

  const setAboutUsPageData = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.setAboutUsPageData().then(response => {
      if (response && response.data && response.data.data) {
        setData(JSON.parse(response.data.data));
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteField = (index) => {
    if (typeof index !== "number") { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      let newData = [...data];
      newData.splice(index, 1);
      ApiService.createAboutUs({ data: JSON.stringify(newData) }).then(response => {
        setData(newData);
        AlertService.alert("success", "Data saved")
      }).catch(error => getFail(error))
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    let newData = [...data];
    newData.unshift(values);
    setButtonSpinner(buttonSpinnerId);
    ApiService.createAboutUs({ data: JSON.stringify(newData) }).then(response => {
      if (response && response.data && response.data.data) {
        setData(JSON.parse(response.data.data));
      }
      extractButtonSpinner(buttonSpinnerId);
      cancel();
      AlertService.alert("success", "Data saved");
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const cancel = () => {
    setValues(values => ({ ...values, title: "", description: "" }));
    setSelectedBrandId(null);
    setIsShowBrandForm(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>About us</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="About us"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              Praesent dui ex
            </h4>
            <p>
              Praesent dui ex, dapibus eget mauris ut, finibus
              vestibulum enim. Quisque arcu leo, facilisis in fringilla
              id, luctus in tortor. Nunc vestibulum est quis orci varius
              viverra. Curabitur dictum volutpat massa vulputate
              molestie. In at felis ac velit maximus convallis.
            </p>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                setIsShowBrandForm(true)
              }}
            >
              Create a field
            </Button>
          </Col>
        </Row>

        <Row>
          {
            data && data.length ?
              <Card>
                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="align-middle">#</th>
                        <th className="align-middle">Title</th>
                        <th className="align-middle">Description</th>
                        <th className="align-middle text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={`${index}`}>
                          <td className="align-middle"><b>{index + 1}</b></td>
                          <td className="align-middle"><b>{item.title}</b></td>
                          <td className="align-middle">{item.description}</td>

                          <td className="align-middle">
                            <div
                              className="d-flex justify-content-center gap-1"
                            >
                              <Button
                                color="danger"
                                onClick={() => {
                                  deleteField(index)
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              : <NoData />
          }
        </Row>
      </div>
      {
        selectedBrandId || isShowBrandForm ?
          <Modal
            show={true}
            onHide={cancel}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create a field</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="field">
                  <Form.Label htmlFor='title'>Title</Form.Label>
                  <Form.Control
                    id='title'
                    type="text"
                    placeholder="Our Story"
                    autoFocus
                    value={values.title}
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        title: event.target.value
                      }))
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="value">
                  <Form.Label htmlFor='description'>Description</Form.Label>
                  <textarea
                    id='description'
                    placeholder="Lorem ipsum dolor sit amet..."
                    value={values.description}
                    className="form-control resize-none"
                    rows="7"
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        description: event.target.value
                      }))
                    }}
                  >
                  </textarea>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="secondary"
                onClick={cancel}
              >
                Close
              </Button>
              <ActionButton
                type="button"
                name="Save"
                color="primary"
                spinnerClass={`w_50`}
                spinnerId={buttonSpinnerId}
                disabled={!values.title.trim().length || !values.description.trim().length}
                onClick={onSubmit}
              />
            </Modal.Footer>
          </Modal>
          : null
      }
    </div>
  )
}
