import moment from "moment";
import React from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { Table, Modal } from "react-bootstrap"
import { serverImgURL } from "../../../helpers";
import ActionButton from "../../../components/Buttons/ActionButton";

const OrderRefund = ({
    order,
    isShowRefundModal,
    setRefundAmount,
    onNumberChange,
    refundAmount,
    buttonSpinnerId,
    refund,
    cancel,
    refundCheckbox,
    setRefundCheckbox
}) => {

    const formatCurrency = (number, decimalPlaces = 0) => {
        return Number(number).toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    }

    return order ?
        <Modal size="lg" show={isShowRefundModal} onHide={cancel} scrollable>
            <Modal.Header closeButton>
                <Modal.Title><b>Refund</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h4 className='m-0 d-flex justify-content-between'>
                                <span>
                                    Order owner
                                </span>
                                {
                                    order.user ?
                                        <span>
                                            {order.user?.firstName} {order.user?.lastName}
                                        </span>
                                        : <span>Unknown client</span>
                                }
                            </h4>
                        </Col>
                        <Col sm={12} className='mt-3'>
                            <h4 className='m-0 d-flex justify-content-between'>
                                <span>
                                    Total amount
                                </span>
                                <span>
                                    {formatCurrency(order.totalAmount, 2)} ֏
                                </span>
                            </h4>
                        </Col>

                        <Col sm={12}>
                            <hr />
                        </Col>
                        <Col sm={12}>
                            {
                                order.comment ?
                                    <div className='border rounded p-3 mt-1'>
                                        <b>Comment:{" "}</b> {order.comment}
                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                    <Row className=''>
                        <Col sm={12}>
                            <div className="mb-3">
                                <Label>Amount*</Label>
                                <Input
                                    type="text"
                                    className={`form-control`}
                                    value={refundAmount}
                                    autoFocus
                                    onChange={(event) => onNumberChange(event, setRefundAmount, order.totalAmount)}
                                />
                            </div>
                            <div className="form-check ">
                                <input
                                    id="refundCheckbox"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={refundCheckbox}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setRefundAmount(order.totalAmount);
                                        } else {
                                            setRefundAmount("")
                                        }
                                        setRefundCheckbox(event.target.checked)
                                    }}
                                />
                                <label
                                    className="form-check-label "
                                    htmlFor="refundCheckbox"
                                >
                                    Select Total Amount
                                </label>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancel}>
                    Close
                </Button>
                <ActionButton
                    type="button"
                    name="Save"
                    color="primary"
                    disabled={!refundAmount}
                    spinnerClass={`w_50`}
                    className="btn btn-primary btn-block px-4"
                    spinnerId={buttonSpinnerId}
                    onClick={refund}
                />
            </Modal.Footer>
        </Modal>
        : null
}

export default OrderRefund;