import {
  SET_CONNECTION
} from "./actionTypes"

const INIT_STATE = {
  connection: null,
}

const Connection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONNECTION:
      return {
        ...state,
        connection: action.payload,
      }
    default:
      return state
  }
}

export default Connection
