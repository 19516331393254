import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import Login from '../../pages/unauthPages/Login'

export default function UnauthMiddleware() {

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Redirect to={`/login`} />
    </Switch>
  )
}