import { takeEvery, fork, all } from "redux-saga/effects"
import { REGISTER_USER } from "./actionTypes"
function* registerUser({ payload: { user } }) { }

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
