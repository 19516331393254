import React, { useState, useEffect, useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Container, Card, CardBody, CardTitle, CardSubtitle, Input, Form, Col, Label, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { withTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import MainService from "./../../../services/mainService"
import { ERROR_KEY, NUMBER_KEY, OBJECT_KEY, VALID_IMAGE_TYPES_KEY, fieldValidation } from '../../../constants/mainKeys'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AlertService from '../../../services/alertService'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { ProgressBar } from 'react-bootstrap'
import { serverImgURL } from '../../../helpers'

const buttonSpinnerId = uuid();
const ProductForm = (props) => {
  const dispatch = useDispatch();
  const mainService = new MainService();
  const { history } = props;

  const [values, setValues] = useState({
    brand: "",
    category: "",
    count: "",
    _description: {
      am: "",
      en: "",
      ru: ""
    },
    images: [],
    _title: {
      am: "",
      en: "",
      ru: "",
    },
    price: "",
    newPrice: "",
    isAvailable: true,
    isSet: false,
  });

  const { productId } = useParams();
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [changes, setChanges] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [fileReadPercent, setFileReadPercent] = useState(0);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    // getBrands();
    getCategoriesList();
    if (productId) getProductById();

    return () => {
      setBrands([]);
      setCategories([]);
    }

  }, [])

  const getProductById = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getProductById(productId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (!data._title) {
          data._title = {
            am: "",
            en: "",
            ru: "",
          }
        }
        if (!data._description) {
          data._description = {
            am: "",
            en: "",
            ru: "",
          }
        }
        setValues(data);
        setProductData(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  // const getBrands = () => {
  //   const spinnerId = uuid();
  //   setPageSpinner(spinnerId);
  //   ApiService.getBrands().then(response => {
  //     if (response && response.data) {
  //       const data = [...response.data];
  //       data.unshift({
  //         title: "Not selected",
  //         _id: "",
  //       })
  //       setBrands(data);
  //     }
  //     extractPageSpinner(spinnerId);
  //   }).catch(error => getFail(error, spinnerId))
  // }

  const getCategoriesList = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCategoriesList().then(response => {
      if (response && response.data && response.data.data) {
        const data = [...response.data.data];
        setCategories(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const uploadFile = async event => {
    const fileEntities = [];
    if (event.target.files) {
      for (let i in event.target.files) {
        const file = event.target.files[i];
        if (file && typeof file === OBJECT_KEY) {
          const fileName = file.name;
          await mainService.readFile(file, VALID_IMAGE_TYPES_KEY, setFileReadPercent).then(uploadedFile => {
            fileEntities.push({
              fileName,
              file,
              uploadedFile
            });
          }).catch(error => error && AlertService.alert("error", "Invalid file format"));
        }
      }
      setValues(() => ({
        ...values,
        images: fileEntities
      }))
    }
  };

  const removeUploadedImage = (index) => {
    if (typeof index !== NUMBER_KEY) { return false; }
    const updatedImages = [...values.images];
    updatedImages.splice(index, 1);
    setValues({ ...values, images: updatedImages });
  };

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onNumberChange = (event, fieldName, maxValue = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && +event.target.value >= 0 && +event.target.value <= maxValue)) {
      setValues(() => ({
        ...values,
        [fieldName]: +event.target.value
      }))
    }
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [fieldName]: item.value
    }))
    setIsInvalidSubmit(false);
    setChanges(true);
  }

  const onChangeDescription = (event, lang, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevValues) => ({
      ...prevValues,
      _description: {
        ...prevValues._description,
        [lang]: event.target.value,
      },
    }));
  };

  const onChangeTitle = (event, lang, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevValues) => ({
      ...prevValues,
      _title: {
        ...prevValues._title,
        [lang]: event.target.value,
      },
    }));
    setIsInvalidSubmit(false);
  };

  const removeImage = (id, imageName) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId);
      ApiService.removeImage("products", id, imageName).then(response => {
        setProductData(value => ({
          ...value,
          image: null
        }))
        AlertService.alert("success", "Data saved");
        extractPageSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (
      !values._title.am.trim().length ||
      !values._title.en.trim().length ||
      !values._title.ru.trim().length ||
      !values.price ||
      !values.category
    ) {
      setIsInvalidSubmit(true);
      return false;
    }
    const formData = new FormData();
    setButtonSpinner(buttonSpinnerId);
    Object.keys(values).forEach((key) => {
      if (key !== "images") {
        // formData.append(key, values[key]);
        if (typeof values[key] === 'object') {
          for (const nestedKey in values[key]) {
            const nestedValue = values[key][nestedKey];
            formData.append(`${key}[${nestedKey}]`, nestedValue);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    });
    if (values.images && values.images.length) {
      values.images.forEach(item => {
        formData.append("image", item.file)
      })
    }
    (productId ? ApiService.updateProduct(productId, formData) : ApiService.createProduct(formData)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert("success", "Data saved");
      history.push("/products")
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Product Form</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Product Form"
        />
        <hr className='mt-0' />
        <Row>
          <Form
            onChange={() => setChanges(true)}
            onSubmit={onSubmit}
          >
            <Row>
              <Col sm={12} className='mb-3'>
                <div className='border rounded p-2'>
                  <Row>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Title (am)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._title.am.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._title.am}
                          onChange={(event) => onChangeTitle(event, "am", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Title (en)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._title.en.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._title.en}
                          onChange={(event) => onChangeTitle(event, "en", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Title (ru)*</Label>
                        <Input
                          type="text"
                          className={`form-control ${isInvalidSubmit && !values._title.ru.trim().length ? "error-border" : ""}`}
                          placeholder="Ex. "
                          value={values._title.ru}
                          onChange={(event) => onChangeTitle(event, "ru", fieldValidation.length_100)}
                        />
                        <small><i>Max {fieldValidation.length_100} symbols</i></small>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label>Price*</Label>
                  <Input
                    type="number"
                    className={`form-control ${isInvalidSubmit && !values.price ? "error-border" : ""}`}
                    placeholder="Ex. 1000"
                    value={values.price}
                    onChange={(event) => onNumberChange(event, "price")}
                  />
                </div>
              </Col>
              {
                categories && categories.length ?
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Category*</Label>
                      <ReactSelectOption
                        value={values.category}
                        selectedValue={(() => {
                          const selectedValue = { ...categories.find(data => data._id === values.category) };
                          if (Object.keys(selectedValue).length) {
                            selectedValue.label = selectedValue._name ? selectedValue._name.am : "";
                            selectedValue.value = selectedValue.value;
                          }
                          return selectedValue;
                        })()}
                        className={`rounded ${isInvalidSubmit && !values.category ? "error-border" : ""}`}
                        isInvalidField={isInvalidSubmit && !values.category}
                        items={categories.map(data => ({ label: data._name ? data._name.am : "", value: data._id }))}
                        onChange={item => onSelectOptionChange(item, "category")}
                      />
                    </div>
                  </Col>
                  : null
              }
              <Col sm={12}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className='d-block'>Is Available</Label>
                      <input
                        id="isAvailable"
                        type="checkbox"
                        className="form-check-input"
                        checked={values.isAvailable}
                        onChange={(event) => {
                          setValues(() => ({
                            ...values,
                            isAvailable: event.target.checked
                          }))
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="isAvailable"
                      >
                        Yes
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className='d-block'>Is Set</Label>
                      <input
                        id="isSet"
                        type="checkbox"
                        className="form-check-input"
                        checked={values.isSet}
                        onChange={(event) => {
                          setValues(() => ({
                            ...values,
                            isSet: event.target.checked
                          }))
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="isSet"
                      >
                        Yes
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} className='mb-3'>
                <div className='border rounded p-2'>
                  <Row>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Description (am)</Label>
                        <textarea
                          name="descriptionAm"
                          rows="4"
                          placeholder='Value'
                          className="form-control resize-none mt-1"
                          value={values._description.am}
                          onChange={(event) => onChangeDescription(event, "am", fieldValidation.length_2000)}
                        />
                        <small><i>Max {fieldValidation.length_2000} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Description (en)</Label>
                        <textarea
                          name="descriptionEn"
                          rows="4"
                          placeholder='Value'
                          className="form-control resize-none mt-1"
                          value={values._description.en}
                          onChange={(event) => onChangeDescription(event, "en", fieldValidation.length_2000)}
                        />
                        <small><i>Max {fieldValidation.length_2000} symbols</i></small>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='mb-3 mb-md-0'>
                        <Label>Description (ru)</Label>
                        <textarea
                          name="descriptionRu"
                          rows="4"
                          placeholder='Value'
                          className="form-control resize-none mt-1"
                          value={values._description.ru}
                          onChange={(event) => onChangeDescription(event, "ru", fieldValidation.length_2000)}
                        />
                        <small><i>Max {fieldValidation.length_2000} symbols</i></small>
                      </div>
                    </Col>

                  </Row>
                </div>
              </Col>

              <Col md={12}>
                <Label>Image</Label>
                <Row>
                  <Col md={6} className="mt-2 border p-2 rounded">
                    <div className='d-flex gap-1 flex-wrap'>
                      {
                        values.images && values.images.length ?
                          values.images.map((item, index) => {
                            return <div key={index} className='position-relative d-inline-block'>
                              <img
                                src={item.uploadedFile}
                                alt="/"
                                style={{ height: "200px", maxWidth: "400px" }}
                                className="img-thumbnail"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  removeUploadedImage(index)
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                          })
                          : productData && productData.image ?
                            <div className='position-relative d-inline-block'>
                              <img
                                src={serverImgURL(productData.image)}
                                alt="/"
                                style={{ height: "200px", maxWidth: "400px" }}
                                className="img-thumbnail"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  removeImage(productData._id, productData.image)
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                            : null
                      }
                    </div>
                    {
                      fileReadPercent > 0 && fileReadPercent < 100 ?
                        <Col md={12} className='mt-2'>
                          <ProgressBar now={fileReadPercent} />
                        </Col>
                        : null
                    }
                    <Button
                      type="button"
                      color={`${!uploadedFile ? "primary" : "danger"}`}
                      className={`px-4 mt-2 ${values.images && values.images.length ? "" : ""}`}
                      disabled={productData && !!productData.image}
                      onClick={(event) => {
                        if (!uploadedFile) {
                          MainService.triggerUploadClick(event);
                        }
                      }}
                    >
                      Upload image
                      <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, "image", setUploadedFile)} />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <hr />
                <div className='d-flex justify-content-end'>
                  <ActionButton
                    type="submit"
                    name="Save"
                    color="primary"
                    disabled={!changes}
                    spinnerClass={`w_50`}
                    className="btn btn-primary btn-block px-4"
                    spinnerId={buttonSpinnerId}
                  />
                </div>
              </Col>
            </Row>
          </Form>

        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(ProductForm)