import React from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { loginUser, removeLoginErrorMessage } from "../../store/actions"
import uuid from 'react-uuid'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ActionButton from '../../components/Buttons/ActionButton'
import mainLogo from "./../../assets/images/logo/logo-dark.png"

const spinnerId = uuid();
const Login = props => {

  const dispatch = useDispatch();
  const usernameMaxLength = 120;
  const passwordMaxLength = 100;
  const loginErrorMessage = useSelector(state => state.Login.loginErrorMessage);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    dispatch(removeLoginErrorMessage());
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!username.trim() || !password.trim()) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      const data = {
        byTelephone: false,
        username: username.trim(),
        password: password.trim()
      }
      dispatch(loginUser(data, spinnerId, props.history));
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#e43f25" }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="">Sign in to continue to Shop.</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <div style={{ border: "1px solid #ced4da" }}>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="#" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={mainLogo}
                              alt="/"
                              height="30"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <form
                        className="form-horizontal"
                        onSubmit={onSubmit}
                      >

                        <div className="mb-3">
                          <label htmlFor='username'>Username</label>
                          <input
                            id='username'
                            name="username"
                            className={`form-control ${isInvalidSubmit && !username.trim() ? "error-border" : ""}`}
                            placeholder="Enter username"
                            type="text"
                            required
                            value={username}
                            onChange={event => onChange(event, setUserName, usernameMaxLength)}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor='password'>Password</label>
                          <input
                            id='password'
                            name="password"
                            className={`form-control ${isInvalidSubmit && !password.trim() ? "error-border" : ""}`}
                            placeholder="Enter password"
                            type="password"
                            required
                            value={password}
                            onChange={event => onChange(event, setPassword, passwordMaxLength)}
                          />
                        </div>

                        {
                          loginErrorMessage === "invalid_username_or_password" ?
                            <span className='text-danger d-block mb-3'>Invalid username or password</span>
                            : null
                        }

                        <div className="form-check">
                          <input
                            id="rememberMe"
                            type="checkbox"
                            className="form-check-input"
                            checked={rememberMe}
                            onChange={event => setRememberMe(event.target.checked)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberMe"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <ActionButton
                            type="submit"
                            name="Log In"
                            className="btn btn-primary btn-block"
                            spinnerId={spinnerId}
                          />
                        </div>

                      </form>
                    </div>
                  </CardBody>
                </div>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  © {new Date().getFullYear()} Sushi land.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)