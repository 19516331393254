import React from 'react';
import { useSelector } from 'react-redux';
import DefaultButtonSpinner from '../Spinners/DefaultButtonSpinner';
import Auxiliary from './../../hoc/auxiliary/auxiliary';
import { Button } from 'reactstrap';

const ActionButton = (props) => {
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);
  return <Button
    type={props.type}
    color={props.color}
    outline={props.outline}
    disabled={currentSpinner || props.disabled ? true : false}
    style={props.style ? props.style : {}}
    className={`d-flex justify-content-center align-items-center ${props.className}`}
    onClick={props.onClick}
  >
    {
      currentSpinner ?
        <Auxiliary>
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
          {" "}Loading
        </Auxiliary> :
        props.name ? props.name : props.children
    }
  </Button>
};

export default ActionButton;  