import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaMapLocationDot } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";


const SidebarContent = (props) => {

  const dispatch = useDispatch();
  const { pathname } = useLocation()

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  const handleAccordionToggle = (accordionKey) => {
    setActiveAccordionKey(accordionKey === activeAccordionKey ? null : accordionKey);
  };

  const setActiveClass = (name) => {
    if (pathname.includes(name)) return "mm-active";
    else return "";
  }

  const removeBodyClasses = () => {
    document.body.classList = '';
  }

  return (
    <section className="scroll-section left-modal-container" id="leftModal">
      <div className="mb-5 border-none custom-accordion-left-menu">

        <Accordion className='left-menu mt-4 pb-3' alwaysOpen defaultActiveKey={[0]}>

          <Accordion.Item title="Dashboard">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("dashboard")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/dashboard`);
              }}
            >
              <i className='bx bxs-dashboard'></i>
              <span>Dashboard</span>
            </Accordion.Header>
          </Accordion.Item>

          {/* <Accordion.Item title="Chat">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("chat")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/chat`);
              }}
            >
              <i className='bx bx-chat'></i>
              <span>Chat</span>
            </Accordion.Header>
          </Accordion.Item> */}

          <Accordion.Item title="Orders">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("orders")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/orders`);
              }}
            >
              <i className='bx bx-dollar-circle' ></i>
              <span>Orders</span>
            </Accordion.Header>
          </Accordion.Item>

          <hr />

          <Accordion.Item title="Categories">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("categories")} ${setActiveClass("category-form")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/categories`);
              }}
            >
              <i className='bx bx-slider-alt'></i>
              <span>Categories</span>
            </Accordion.Header>
          </Accordion.Item>

          {/* <Accordion.Item title="Brands">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("brand")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/brands`);
              }}
            >
              <i className='bx bx-spreadsheet'></i>
              <span>Brands</span>
            </Accordion.Header>
          </Accordion.Item> */}

          <Accordion.Item title="Products">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("product")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/products`);
              }}
            >
              <i className='bx bx-store'></i>
              <span>Products</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Users">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("users")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/users`);
              }}
            >
              <i className='bx bx-user'></i>
              <span>Users</span>
            </Accordion.Header>
          </Accordion.Item>

          <hr />
          <Accordion.Item title="Regions">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("region")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/regions`);
              }}
            >
              <FaMapLocationDot size={20} style={{ marginRight: "10px" }} />
              <span>Regions</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Cities">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("cities")} ${setActiveClass("city-form")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/cities`);
              }}
            >
              <HiOutlineLocationMarker size={20} style={{ marginRight: "10px" }} />
              <span>Cities</span>
            </Accordion.Header>
          </Accordion.Item>

          <hr />

          <Accordion.Item title="Contact Us">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("contact-us")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/contact-us`);
              }}
            >
              <i className='bx bxs-contact' ></i>
              <span>Contact Us</span>
            </Accordion.Header>
          </Accordion.Item>

          {/* <Accordion.Item title="About us">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("about-us")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/about-us`);
              }}
            >
              <i className='bx bx-cool'></i>
              <span>About Us</span>
            </Accordion.Header>
          </Accordion.Item> */}


          <Accordion.Item title="Configs">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("/configs")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/configs`);
              }}
            >
              <i className='bx bx-cog'></i>
              <span>Configs</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Payment Systems">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("/paymentSystems")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/paymentSystems`);
              }}
            >
              <i className='bx bx-credit-card-alt'></i>
              <span>Payment Systems</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Library">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("library")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/library`);
              }}
            >
              <i className='bx bx-list-plus'></i>
              <span>Library</span>
            </Accordion.Header>
          </Accordion.Item>




        </Accordion>

      </div>
    </section>
  )
}

export default withRouter(SidebarContent)