import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ActionButton from '../../../components/Buttons/ActionButton'
import MetaTags from 'react-meta-tags'

const buttonSpinnerId = uuid();
export default function ContactUs(props) {

  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [values, setValues] = useState({
    key: "",
    field: "",
    value: "",
  })

  useEffect(() => {
    setContactUsPageData();
    return () => {
      setData([])
    }
  }, [])

  const setContactUsPageData = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.setContactUsPageData().then(response => {
      if (response && response.data && response.data.data) {
        setData(JSON.parse(response.data.data));
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteContact = (index) => {
    if (typeof index !== "number") { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      let newData = [...data];
      newData.splice(index, 1);
      ApiService.createContactUs({ data: JSON.stringify(newData) }).then(response => {
        setData(newData);
        AlertService.alert("success", "Data saved")
      }).catch(error => getFail(error))
    })
  }

  const selectItem = (item, index) => {
    item.index = index;
    setValues({ ...item });
    setIsShowForm(true);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    let newData = [...data];
    if (typeof values.index === "number") {
      let idx = values.index;
      newData[idx] = values;
      newData = newData.map(obj => {
        const { index, ...newObj } = obj;
        return newObj;
      });
    } else {
      newData.unshift(values);
    }

    setButtonSpinner(buttonSpinnerId);
    ApiService.createContactUs({ data: JSON.stringify(newData) }).then(response => {
      if (response && response.data && response.data.data) {
        setData(JSON.parse(response.data.data));
      }
      extractButtonSpinner(buttonSpinnerId);
      cancel();
      AlertService.alert("success", "Data saved");
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const cancel = () => {
    setValues(values => ({ ...values, field: "", value: "", key: "" }));
    setIsShowForm(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Contact us</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Contacts"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                setIsShowForm(true)
              }}
            >
              Create a contact
            </Button>
          </Col>
        </Row>
        <Row>
          {
            data && data.length ?
              <Col sm={12}>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th className="align-middle">#</th>
                      <th className="align-middle">Key</th>
                      <th className="align-middle">Title</th>
                      <th className="align-middle">Description</th>
                      <th className="align-middle text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={`${index}`}>
                        <td className="align-middle"><b>{index + 1}</b></td>
                        <td className="align-middle">{item.key}</td>
                        <td className="align-middle">{item.field}</td>
                        <td className="align-middle">{item.value}</td>
                        <td className="align-middle">
                          <div
                            className="d-flex justify-content-center gap-1"
                          >
                            <Button
                              color="warning"
                              onClick={() => {
                                selectItem(item, index);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => {
                                deleteContact(index)
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              : <NoData />
          }
        </Row>
      </div>
      {
        isShowForm ?
          <Modal
            show={true}
            onHide={cancel}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create a contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="field">
                  <Form.Label htmlFor='key'>Key</Form.Label>
                  <Form.Control
                    id='key'
                    type="text"
                    placeholder="Phone"
                    autoFocus
                    value={values.key}
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        key: event.target.value
                      }))
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="field">
                  <Form.Label htmlFor='country'>Title</Form.Label>
                  <Form.Control
                    id='country'
                    type="text"
                    placeholder="Phone"
                    value={values.field}
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        field: event.target.value
                      }))
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="value">
                  <Form.Label htmlFor='phoneNumber'>Description</Form.Label>
                  <Form.Control
                    id='phoneNumber'
                    type="text"
                    placeholder="123456789"
                    value={values.value}
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        value: event.target.value
                      }))
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="secondary"
                onClick={cancel}
              >
                Close
              </Button>
              <ActionButton
                type="button"
                name="Save"
                color="primary"
                spinnerClass={`w_50`}
                spinnerId={buttonSpinnerId}
                disabled={!values.key.trim().length || !values.field.trim().length || !values.value.trim().length}
                onClick={onSubmit}
              />
            </Modal.Footer>
          </Modal>
          : null
      }
    </div>
  )
}
