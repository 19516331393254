import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ActionButton from '../../../components/Buttons/ActionButton'
import { MetaTags } from 'react-meta-tags'

const buttonSpinnerId = uuid();
export default function Brands(props) {

  const dispatch = useDispatch()
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [isShowBrandForm, setIsShowBrandForm] = useState(false);
  const [values, setValues] = useState({
    title: ""
  })

  useEffect(() => {
    geBrands();
    return () => {
      setBrands([])
    }
  }, [])

  const geBrands = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getBrands().then(response => {
      if (response && response.data) {
        setBrands(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteBrand = (brandId, spinnerId) => {
    if (!brandId) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      ApiService.deleteBrand(brandId).then(response => {
        setBrands(brands.filter(item => item._id !== brandId));
        AlertService.alert("success", "Brand deleted successfully");
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const editBrand = (brand) => {
    if (!brand) { return false; }
    setSelectedBrandId(brand._id);
    setValues((values) => ({
      ...values,
      title: brand.title
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    (selectedBrandId ? ApiService.updateBrand(selectedBrandId, values) : ApiService.createBrand(values)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      let newBrands = [...brands];
      if (response && response.data) {
        if (!selectedBrandId) {
          newBrands.unshift(response.data);
        } else {
          brands.forEach(item => {
            if (item._id === selectedBrandId) {
              item.title = response.data.title;
            }
          })
        }
      }
      cancel();
      setBrands(newBrands);
      AlertService.alert("success", "Data saved");
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const cancel = () => {
    setValues(values => ({ ...values, title: "" }));
    setSelectedBrandId(null);
    setIsShowBrandForm(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Brands</title>
      </MetaTags>
      <Breadcrumbs
        title="Sushi land"
        breadcrumbItem="Brands"
      />
      <div className="container-fluid">
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              How to create/edit a brand?
            </h4>
            <p>
              Praesent dui ex, dapibus eget mauris ut, finibus
              vestibulum enim. Quisque arcu leo, facilisis in fringilla
              id, luctus in tortor. Nunc vestibulum est quis orci varius
              viverra. Curabitur dictum volutpat massa vulputate
              molestie. In at felis ac velit maximus convallis.
            </p>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                setIsShowBrandForm(true)
              }}
            >
              Create a brand
            </Button>
          </Col>
        </Row>
        <Row>
          {
            brands && brands.length ?
              <Card>
                <CardBody>
                  <Table bordered responsive className="text-center">
                    <thead>
                      <tr>
                        <th className="align-middle">#</th>
                        <th className="align-middle">Name</th>
                        <th className="align-middle">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {brands.map((item, index) => (
                        <tr key={`${item._id}`}>
                          <td className="align-middle"><b>{index + 1}</b></td>
                          <td className="align-middle"><b>{item.title}</b></td>

                          <td className="align-middle">
                            <div
                              className="d-flex justify-content-center gap-1"
                            >
                              <Button
                                color="warning"
                                onClick={() => {
                                  editBrand(item)
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => {
                                  deleteBrand(item._id)
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              : <NoData />
          }
        </Row>
      </div>
      {
        selectedBrandId || isShowBrandForm ?
          <Modal
            show={true}
            onHide={cancel}
          >
            <Modal.Header closeButton>
              <Modal.Title>{!selectedBrandId ? "Create a" : "Update the"} brand</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Brand 1"
                    autoFocus
                    value={values.title}
                    onChange={(event) => {
                      setValues(values => ({
                        ...values,
                        title: event.target.value
                      }))
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="secondary"
                onClick={cancel}
              >
                Close
              </Button>
              <ActionButton
                type="button"
                name="Save"
                color="primary"
                spinnerClass={`w_50`}
                spinnerId={buttonSpinnerId}
                disabled={!values.title.trim().length}
                onClick={onSubmit}
              />
            </Modal.Footer>
          </Modal>
          : null
      }
    </div>
  )
}
