import React, { useState, useEffect, useRef } from 'react'
import { MetaTags } from 'react-meta-tags'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Button,
  Card,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Link } from "react-router-dom"
import defaultUserImage from "./../../../assets/images/users/default-user.png"
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { withRouter, useParams } from "react-router-dom"
import { useWindowSize } from "../../../components/hooks/useWindowSize"
import NoData from '../../../components/NoData/NoData'


const Chat = (props) => {

  const { history } = props;
  const { clientId } = useParams();
  const refChatArea = useRef(null);
  const { width, height } = useWindowSize();
  const { connection } = useSelector(state => state.Connection);
  const [message, setMessage] = useState("");
  const [clients, setClients] = useState([]);
  const [activeUserData, setActiveUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [impulsedUserId, setImpulsedUserId] = useState(null);

  useEffect(() => {

    if (connection) {
      connection.on("clients", onGetClients);
      connection.on("clientMessages", onGetChat);
      connection.on("newMessage", onNewMessage);

      return () => {
        connection.off("clients");
        connection.off("clientMessages");
        connection.off("newMessage");
      };
    }
  }, [connection, activeUserData, messages, clients]);

  useEffect(() => {
    if (connection) {
      connection.emit("getClients");
    }
  }, [connection]);

  useEffect(() => {
    if (clients && clients.length && clientId) {
      const isExist = clients.find(item => item._id === clientId);
      if (isExist) setActiveUserData(isExist);
      else history.push(`/chat`);
    }
  }, [clients, clientId])


  useEffect(() => {
    if (activeUserData) {
      connection.emit("getClientMessages", { id: activeUserData._id, isAdmin: true });
    }
  }, [activeUserData, connection]);

  const onGetClients = (clients) => {
    if (clients && clients.length) {
      setClients(clients)
    }
  };

  const onGetChat = (data) => {
    if (data && data.length) {
      setMessages(data);
      scrollChat();
    }
  };

  const onSendMessage = (event) => {
    event && event.preventDefault();
    if (message.trim() !== "") {
      setMessage("");
      connection.emit("sendMessage", {
        isAdmin: true,
        id: activeUserData._id,
        message: message.trim(),
      });
    }
  };

  const onNewMessage = (msg) => {
    if (msg.recipient === null || msg.sender === null) {
      if (activeUserData && (msg.sender === activeUserData._id || msg.recipient === activeUserData._id)) {
        setMessages([...messages, msg]);
        if (!clients.length) connection.emit("getClients");
        scrollChat();
      }
      if (!activeUserData || (activeUserData && activeUserData._id !== msg.sender)) {
        setImpulsedUserId(msg.sender);
      }
    }
  };

  const scrollChat = () => {
    if (refChatArea.current) {
      const chatContainer = refChatArea.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  return (
    <div className='page-content position-relative'>
      <MetaTags>
        <title>Chat</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Sushi land"
          breadcrumbItem="Chat"
        />
        <hr className='mt-0' />
        <Row>
          <Col lg={5}>
            <div>
              <h5 className="font-size-14 mb-3">Users</h5>
              <div
                className='pe-1 mb-3'
              // style={{ height: `calc(${height - 400}px)`, overflow: "auto" }}
              >
                <ul className="list-unstyled chat-list">
                  {
                    clients && clients.length ?
                      clients.map((item, index) => {
                        return <li
                          key={index}
                          className={`border rounded ${activeUserData && activeUserData._id === item._id ? "active" : ""}`}
                          style={{ marginTop: "1px" }}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (activeUserData && activeUserData._id === item._id) return false;
                              setActiveUserData(item);
                              setImpulsedUserId(null);
                              history.push("/chat");
                            }}
                          >
                            <div className='media align-items-center'>
                              <img
                                src={defaultUserImage}
                                className="rounded-circle avatar-xs me-3"
                                alt="/"
                              />
                              <h5 className="text-truncate font-size-14 mb-0">
                                {item.firstName} {item.lastName}
                              </h5>
                              {
                                impulsedUserId && impulsedUserId === item._id ?
                                  <i className={"mdi mdi-circle font-size-10 ms-3 pulse-icon"} />
                                  : null
                              }
                            </div>
                          </Link>
                        </li>
                      })
                      : <NoData width={200} />
                  }
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div>
              <h5 className="font-size-14 mb-3">Chat</h5>
              <div className="w-100 user-chat">
                <Card>
                  {
                    activeUserData ?
                      <div className="p-4 border-bottom ">
                        <Row>
                          <Col md="12">
                            <h5 className="font-size-15 mb-1">
                              {activeUserData.firstName} {activeUserData.lastName}
                            </h5>
                          </Col>
                        </Row>
                      </div>
                      : null
                  }

                  <div>
                    <div className="chat-conversation p-3 pe-1">
                      <ul className="list-unstyled">
                        <div
                          ref={refChatArea}
                          className='pe-1'
                          style={{ height: `calc(${height - 500}px)`, overflow: "auto" }}
                        >
                          {
                            messages && messages.length ?
                              messages.map((item, index) => {
                                if (item.recipient) {
                                  return <li
                                    key={index}
                                    className={"right ms-auto"}
                                    style={{ maxWidth: "90%" }}
                                  >
                                    <div className="conversation-list">
                                      <div className="ctext-wrap">
                                        <p className='mt-2' style={{ wordBreak: "break-all", textAlign: "left" }}>
                                          {item.content}
                                        </p>
                                        <small className="chat-time mb-0 font-size-10" style={{ textAlign: "left" }}>
                                          <i className="bx bx-time-five align-middle me-1" />
                                          {moment(item.createdAt).format("ll HH:mm")}
                                        </small>
                                      </div>
                                    </div>
                                  </li>
                                } else {
                                  return <li
                                    key={index}
                                    style={{ maxWidth: "90%" }}
                                  >
                                    <div className="conversation-list">
                                      <div className="ctext-wrap bg-primary">
                                        <p style={{ wordBreak: "break-all" }} className='text-white'>
                                          {item.content}
                                        </p>
                                        <small className="chat-time mb-0 text-white font-size-10" style={{ textAlign: "right" }}>
                                          <i className="bx bx-time-five align-middle me-1" />
                                          {moment(item.createdAt).format("ll HH:mm")}
                                        </small>
                                      </div>
                                    </div>
                                  </li>
                                }
                              })
                              : null
                          }
                        </div>
                      </ul>
                    </div>
                    <div className="p-3 chat-input-section">
                      <form onSubmit={onSendMessage}>
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={message}
                                onChange={event => {
                                  if (event.target.value.trim().length > 2000) {
                                    return false;
                                  }
                                  setMessage(event.target.value);
                                }}
                                className="form-control chat-input"
                                max={10}
                                placeholder="Enter Message..."
                                disabled={!activeUserData}
                              />
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={onSendMessage}
                              className="btn btn-primary btn-rounded chat-send w-md"
                              disabled={!activeUserData}
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(Chat)
