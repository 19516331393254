// @flow
import {
  SET_ORDERS,
  SET_ORDERS_COUNT,
  SET_PENDING_ORDER_IDS,
  SET_ORDER_SEARCH,
  SET_DATE_FROM,
  SET_DATE_TO,
} from "./actionTypes"

const INIT_STATE = {
  orders: [],
  ordersCount: 0,
  pendingOrderIds: [],
  orderSearch: "",
  dateFrom: "",
  dateTo: "",
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }
    case SET_ORDERS_COUNT:
      return {
        ...state,
        ordersCount: action.payload,
      }
    case SET_PENDING_ORDER_IDS:
      return {
        ...state,
        pendingOrderIds: action.payload,
      }
    case SET_ORDER_SEARCH:
      localStorage.setItem("orderSearch", action.payload)
      return {
        ...state,
        orderSearch: action.payload,
      }
    case SET_DATE_FROM:
      localStorage.setItem("dateFrom", action.payload)
      return {
        ...state,
        dateFrom: action.payload,
      }
    case SET_DATE_TO:
      localStorage.setItem("dateTo", action.payload)
      return {
        ...state,
        dateTo: action.payload,
      }

    default:
      return state
  }
}

export default Layout
